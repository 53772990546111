import { File } from '../../File.entity'
import { Activity } from '../../Form2/Form2.entity'
import { Organization } from '../../Organization.entity'
import { SubmittedBy } from '../../SubmittedBy.entity'
import { FileDescription } from './Common/FileDescription.entity'
import { Program } from './Common/Program.entity'

export const NumberOfSteps1 = 8
export const NumberOfSteps2 = 5

export interface Form7_4_1Entity {
    report_id: string
    rep_year: number
    submitted_date?: Date
    submitted_place?: Date
    category: string
    organisation: Organization
    status: string

    o7_4_1_k1_z1: number
    o7_4_1_k1_z2: number
    o7_4_1_k1_m1: number
    o7_4_1_k1_m2: number

    o7_4_1_k2_t1_z1: number
    o7_4_1_k2_t1_z2: number
    o7_4_1_k2_t1_m1: number
    o7_4_1_k2_t1_m2: number

    o7_4_1_k2_t2_z1: number
    o7_4_1_k2_t2_z2: number
    o7_4_1_k2_t2_m1: number
    o7_4_1_k2_t2_m2: number

    o7_4_1_k3_t1_z1: number
    o7_4_1_k3_t1_z2: number
    o7_4_1_k3_t1_m1: number
    o7_4_1_k3_t1_m2: number

    o7_4_1_k3_t2_z1: number
    o7_4_1_k3_t2_z2: number
    o7_4_1_k3_t2_m1: number
    o7_4_1_k3_t2_m2: number

    o7_4_1_k4_t1_z1: number
    o7_4_1_k4_t1_z2: number
    o7_4_1_k4_t1_m1: number
    o7_4_1_k4_t1_m2: number

    o7_4_1_k4_t2_z1: number
    o7_4_1_k4_t2_z2: number
    o7_4_1_k4_t2_m1: number
    o7_4_1_k4_t2_m2: number

    o7_4_1_k5_z1: number
    o7_4_1_k5_z2: number
    o7_4_1_k5_m1: number
    o7_4_1_k5_m2: number

    o7_4_1_k6_sektori?: Activity[]

    o7_4_1_k7_z1: number
    o7_4_1_k7_z2: number
    o7_4_1_k7_z3: number
    o7_4_1_k7_z4: number
    o7_4_1_k7_z5: number
    o7_4_1_k7_z6: number
    o7_4_1_k7_z7: number
    o7_4_1_k7_m1: number
    o7_4_1_k7_m2: number
    o7_4_1_k7_m3: number
    o7_4_1_k7_m4: number
    o7_4_1_k7_m5: number
    o7_4_1_k7_m6: number
    o7_4_1_k7_m7: number

    submitted_by?: SubmittedBy
}

export interface Form7_4_2Entity {
    report_id: string
    rep_year: number
    submitted_date?: Date
    submitted_place?: Date
    category: string
    organisation: Organization
    status: string

    //sektori:
    o7_4_2_k1_prezivljavanje: Activity[]

    o7_4_2_k2_vlasnistvo: Activity[]

    o7_4_2_k3_programs: Program[]

    o7_4_2_k3_z: number // not required (%)

    o7_4_2_k4_fileDescriptions: FileDescription[]

    o7_4_2_k4_file: File[]

    submitted_by?: SubmittedBy
}
