import { File } from '../../File.entity'
import { Activity } from '../../Form2/Form2.entity'
import { Organization } from '../../Organization.entity'
import { SubmittedBy } from '../../SubmittedBy.entity'
import { FileDescription } from './Common/FileDescription.entity'
import { Program } from './Common/Program.entity'

export const NumberOfSteps = 7

export interface Form7_12Entity {
    report_id?: string
    rep_year?: number
    submitted_date?: Date
    submitted_place?: Date
    category?: string
    organisation?: Organization
    status?: string

    // Step 1
    o7_12_k1_tekuca?: number
    o7_12_k1_prethodna?: number

    //Step 2
    o7_12_k2_z_tg_1?: number
    o7_12_k2_z_tg?: number

    o7_12_k2_m_tg_1?: number
    o7_12_k2_m_tg?: number

    //Step 3
    o7_10_k3_pristup?: number
    o7_10_k3_standard?: number
    o7_10_k3_files?: File[]

    //Step4
    o7_10_k4_broj_obucenih?: number
    o7_10_k4_procenat_obucenih?: number
    o7_10_k4_broj_informativnih?: number

    // Step 5
    o7_10_k5_nazivi?: Naziv[]
    o7_10_k5_files?: File[]

    submitted_by?: SubmittedBy
}

export interface Naziv {
    id?: string
    naziv?: string
    vrsta?: string
    link?: string
}
