export const env: string = process.env.REACT_APP_ENV || 'prod'

const urls: any = {
    local: {
        baseUrl: 'http://localhost:3003',
    },
    dev: {
        baseUrl: 'https://dev.api.zrr.we-deliver.net',
    },
    prod: {
        baseUrl: 'https://dev.api.zrr.we-deliver.net',
        // baseUrl: 'https://api.test.rr.minljmpdd.gov.rs',
    },
    prod_gov_test: {
        // baseUrl: 'https://dev.api.zrr.we-deliver.net',
        baseUrl: 'https://api.test.rr.minljmpdd.gov.rs',
    },
}

export const baseUrl = urls[env].baseUrl
