import React from 'react'
import { StepInterface } from './Step1'
import RadioButtonsControl from '../../../../../components/Common/RadioButtonsControlWithError'
import Textbox from '../../../../../components/Common/Textbox'
import ActionButtons from '../../../../../components/ActionButtons'
import { Form7_14_2Entity } from '../../../../../models/Form7/Entities/Form7_14.entity'
import { useWatch } from 'react-hook-form'

export const Step2 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView, clearErrors }: StepInterface) => {
    const watchedValues = useWatch<Form7_14_2Entity>({ control })

    const fields: ('o7_14_2_k3_ocena' | 'o7_14_2_k3_opis')[] = ['o7_14_2_k3_ocena', 'o7_14_2_k3_opis']

    const disabledNext = !fields.every((e) => watchedValues[e] !== null && watchedValues[e] !== undefined && watchedValues[e]?.toString() !== '')

    const goToNextStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView) nextStep()
    }
    return (
        <div>
            <h1 className="step-name">Степен укључености организација ОЦД у планирање и реализацију родно одговорних јавних политика на свим нивоима</h1>
            <div className="flex flex-row gap-2 ">
                <RadioButtonsControl
                    control={control}
                    name={fields[0]}
                    options={['Одличан', 'Добар', 'Довољан', 'Недовољан', 'Лош']}
                    errors={errors}
                    enumerated
                    disabled={adminView}
                />
            </div>
            <div className="mt-[50px] ">
                <h4 className="mb-[10px]">Молим Вас да кратко опишете зашто сте дали овакву оцену?</h4>
                <div className="flex flex-col">
                    <Textbox
                        name="o7_14_2_k3_opis"
                        control={control}
                        placeholder="Текст (400 карактера)*"
                        errors={errors}
                        isRequired={adminView ? false : 'Попуните обавезно поље.'}
                        maxNumberOfCharacters={400}
                        disabled={adminView}
                    />
                    <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] mb-10">{errors.o7_14_2_k3_opis ? 'Попуните обавезно поље!' : ''}</span>
                </div>
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}
