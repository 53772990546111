import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Form7_4_1Entity, NumberOfSteps1 } from '../../../../models/Form7/Entities/Form7_4.entity'
import { useUser } from '../../../../auth/UseUser'
import Step1 from './Steps/Step1'
import Step2 from './Steps/Step2'
import Step3 from './Steps/Step3'
import Step4 from './Steps/Step4'
import Step5 from './Steps/Step5'
import Step6 from './Steps/Step6'
import Step7 from './Steps/Step7'
import { FORM7_NAMES } from '../../../../models/Form7/Form7Names'
import Stepper from '../../../../components/Common/Stepper/Stepper'
import { FORM7_TITLE } from '../../../../models/FormTitles'
import LoadingSpinner from '../../../../components/Common/LoadingSpinner'
import FinishOfFormModal from '../../../../components/Common/FinishOfFormModal'
import Step8 from './Steps/Step8'
import HelpButton from '../../../../components/Common/HelpButton'
import { HELP_BUTTON_7_4_1 } from '../../../../models/Form7/Names/Form7_4_1Names'

const Form7_4_1Screen = () => {
    const { user } = useUser()

    let navigate = useNavigate()
    const [finishModalVisible, setFinishModalVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [activeStep, setActiveStep] = useState(0)
    let [searchParams, setSearchParams] = useSearchParams()
    const adminUsage = searchParams.get('adminUsage') === '1'

    const formId = searchParams.get('formId')
    const adminView = user.admin

    const {
        handleSubmit,
        control,
        getValues,
        reset,
        setValue,
        unregister,
        clearErrors,
        formState: { errors },
    } = useForm<Form7_4_1Entity>({
        defaultValues: { o7_4_1_k6_sektori: [{ level: '', maleNumber: undefined, femaleNumber: undefined }] },
    })

    const onSelectStep = (step: number) => setActiveStep(step)
    const nextStep = async () => {
        const form = getValues()
        if (!adminView) {
            await save()
            // if (activeStep === 0 && form.status === undefined) getUnfinishedForm()
        }
        if (NumberOfSteps1 > activeStep + 1) setActiveStep(activeStep + 1)
    }
    const save = async () => {
        console.log(getValues())
        // const resp = await saveForm6(getValues(), activeStep + 1)
        // if (resp.status == 201) {
        //     setValue('report_id', resp.data.report_id)
        // } else {
        //     alert(`Грешка при чувању података: ${resp.status}`)
        // }
    }
    const previousStep = () => setActiveStep(activeStep - 1)

    const onSubmit = async () => {
        // console.log('on submit get values ', getValues())
    }

    const finish = async () => {
        if (!adminView) {
            await save()
        }
        setFinishModalVisible(true)
    }

    const resetValuesAndGoToFirstStep = () => {
        setFinishModalVisible(false)
        setActiveStep(0)
        reset()
        navigate('/')
    }

    const stepComponent = () => {
        switch (activeStep) {
            case 0:
                return (
                    <Step1
                        control={control}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        previousButtonDisabled={activeStep === 0}
                        errors={errors}
                        adminView={adminView}
                        clearErrors={clearErrors}
                    />
                )
            case 1:
                return <Step2 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 2:
                return <Step3 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} setValue={setValue} adminView={adminView} />
            case 3:
                return <Step4 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 4:
                return <Step5 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 5:
                return <Step6 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} clearErrors={clearErrors} />
            case 6:
                return <Step7 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} clearErrors={clearErrors} />
            case 7:
                return <Step8 control={control} nextStep={finish} previousStep={previousStep} errors={errors} adminView={adminView} setValue={setValue} />
            default:
                return null
        }
    }
    return (
        <div className="max-w-[1556px] mx-[auto] px-[40px] pb-[20px]">
            <div className="pt-[40px] pb-[55px] flex flex-row gap-5 items-start ">
                <h1 className="text-[24px] font-light"> {FORM7_NAMES[3]} </h1>
                <HelpButton name={HELP_BUTTON_7_4_1[activeStep]} marginTop={5} />
            </div>
            <div>
                <Stepper numberOfSteps={NumberOfSteps1} onSelectStep={onSelectStep} activeStep={activeStep} formName={undefined} unlockEveryStep={!!adminUsage || adminView} />
            </div>
            {!loading ? (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mt-[55px]">{stepComponent()}</div>
                </form>
            ) : (
                <div className="flex justify-center items-center h-full mt-[100px]">
                    <LoadingSpinner />
                </div>
            )}
            <FinishOfFormModal modalIsOpen={finishModalVisible} closeModal={resetValuesAndGoToFirstStep} infoText={FORM7_TITLE} adminView={adminView} />
        </div>
    )
}

export default Form7_4_1Screen
