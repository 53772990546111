import React from 'react'
import { Form7_13Entity } from '../../../../../models/Form7/Entities/Form7_13.entity'
import { Control, useWatch } from 'react-hook-form'
import PositiveNumberInputCell from '../../../../Form2/Components/PositiveNumberInputCell'
import ActionButtons from '../../../../../components/ActionButtons'

export interface StepInterface {
    control?: Control<Form7_13Entity>
    nextStep: () => void
    previousStep: () => void
    previousButtonDisabled?: boolean
    errors?: any
    adminView?: boolean
    setValue?: any
    clearErrors?: any
}

export const Step1 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView, clearErrors }: StepInterface) => {
    const watchedValues = useWatch<Form7_13Entity>({ control })
    const headers = [
        'Број прописа који регулишу механизме РР на свим нивоима организације власти у складу са Законом о родној равноправности, њихов статус, надлежности и процедуре, и који дефинишу процедуре учешћа у креирању родно одговорних јавних политика',
        'Број механизама РР на националном покрајинском и локалном нивоу власти и свим нивоима унутрашње организације које имају обезбеђене људске, финансијске и материјалне услове за функционисање, разврстано по нивоима организације рада, регионима/територијалној припадности',
        'Број родно одговорних јавних политика  и области на које се односе на свим нивоима у којима активно учествују механизми РР',
    ]

    const fields: ('o7_13_k1_br_propisa' | 'o7_13_k1_br_mehanizama' | 'o7_13_k1_br_odgovornih')[] = ['o7_13_k1_br_propisa', 'o7_13_k1_br_mehanizama', 'o7_13_k1_br_odgovornih']

    const disabledNext = !fields.every((e) => {
        return watchedValues[e] !== null && watchedValues[e] !== undefined && watchedValues[e]?.toString() !== ''
    })

    const goToNextStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView) {
            nextStep()
        }
    }

    return (
        <div>
            <h1 className="step-name mb-[10px]">{headers[0]}</h1>
            <div className="max-w-[200px] flex flex-col">
                <PositiveNumberInputCell
                    name={`${fields[0]}`}
                    control={control}
                    errors={errors}
                    isRequired={!adminView}
                    height={62}
                    disabled={adminView}
                    form7
                    clearErrors={clearErrors}
                />

                <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] mb-10">{errors.o7_13_k1_br_propisa ? 'Попуните обавезно поље!' : ''}</span>
            </div>
            <h1 className="step-name mb-[10px]">{headers[1]}</h1>
            <div className="max-w-[200px] flex flex-col">
                <PositiveNumberInputCell
                    name={`${fields[1]}`}
                    control={control}
                    errors={errors}
                    isRequired={!adminView}
                    height={62}
                    disabled={adminView}
                    form7
                    clearErrors={clearErrors}
                />

                <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px]  mb-10">{errors.o7_13_k1_br_mehanizama ? 'Попуните обавезно поље!' : ''}</span>
            </div>
            <h1 className="step-name mb-[10px]">{headers[2]}</h1>
            <div className="max-w-[200px] flex flex-col">
                <PositiveNumberInputCell
                    name={`${fields[2]}`}
                    control={control}
                    errors={errors}
                    isRequired={!adminView}
                    height={62}
                    disabled={adminView}
                    form7
                    clearErrors={clearErrors}
                />
                <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] ">{errors.o7_13_k1_br_odgovornih ? 'Попуните обавезно поље!' : ''}</span>
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}
