import React, { useState } from 'react'
import { ReactComponent as UploadCloud } from '../../../assets/svg/UploadCloud.svg'
import { ReactComponent as Trash } from '../../../assets/svg/Trash.svg'
import { FileUploader } from 'react-drag-drop-files'
import Textbox from '../../../components/Common/Textbox'
import UploadFileMock from '../../../components/Common/UploadFileMock'
import UploadFile from '../../../components/UploadFile'

interface FileInputComponentInterface {
    name: string
    control: any
    label: string
    errors?: any
    isRequired?: any
    clearErrors?: any
    disabled?: any
}

function FileInputComponent({ name, control, label, errors, isRequired, clearErrors, disabled }: FileInputComponentInterface) {
    return (
        <div>
            <div className="mt-[50px]">
                <h4 className="mb-[20px]">{label}:</h4>
                <UploadFile control={control} name={name} errors={errors} isRequired={isRequired} disabled={disabled} clearErrors={clearErrors} />
            </div>
        </div>
    )
}

export default FileInputComponent
