import React from 'react'
import { Form7_1Entity } from '../../../../../models/Form7/Entities/Form7_1.entity'
import { StepInterface } from './Step1'
import { useWatch } from 'react-hook-form'
import ComplexTable from '../../../../../components/Tables/ComplexTable'
import ActionButtons from '../../../../../components/ActionButtons'

interface RowInterface {
    label: string
    fields: (keyof Form7_1Entity)[]
}

const rows: RowInterface[] = [
    { label: 'Од 15-19 година живота', fields: ['o7_1_k6_z_rukovodioci', 'o7_1_k6_m_rukovodioci'] },
    { label: 'Од 20-24 година живота', fields: ['o7_1_k6_z_strucnjaci', 'o7_1_k6_m_strucnjaci'] },
    { label: 'Од 25-29 година живота', fields: ['o7_1_k6_z_inzenjeri', 'o7_1_k6_m_inzenjeri'] },
    { label: 'Од 30-34 година живота', fields: ['o7_1_k6_z_administratori', 'o7_1_k6_m_administratori'] },
    { label: 'Од 35-39 година живота', fields: ['o7_1_k6_z_usluzna', 'o7_1_k6_m_usluzna'] },
    { label: 'Од 40-44 година живота', fields: ['o7_1_k6_z_poljoprivredna', 'o7_1_k6_m_poljoprivredna'] },
    { label: 'Од 45-49 година живота', fields: ['o7_1_k6_z_zanatlije', 'o7_1_k6_m_zanatlije'] },
    { label: 'Од 50-54 година живота', fields: ['o7_1_k6_z_rukovaoci', 'o7_1_k6_m_rukovaoci'] },
    { label: 'Од 55-59 година живота', fields: ['o7_1_k6_z_jednostavna', 'o7_1_k6_m_jednostavna'] },
    { label: 'Од 60-64 година живота', fields: ['o7_1_k6_z_vojna', 'o7_1_k6_m_vojna'] },
]

const allFields = [
    'o7_1_k6_z_rukovodioci',
    'o7_1_k6_z_strucnjaci',
    'o7_1_k6_z_inzenjeri',
    'o7_1_k6_z_administratori',
    'o7_1_k6_z_usluzna',
    'o7_1_k6_z_poljoprivredna',
    'o7_1_k6_z_zanatlije',
    'o7_1_k6_z_rukovaoci',
    'o7_1_k6_z_jednostavna',
    'o7_1_k6_z_vojna',

    'o7_1_k6_m_rukovodioci',
    'o7_1_k6_m_strucnjaci',
    'o7_1_k6_m_inzenjeri',
    'o7_1_k6_m_administratori',
    'o7_1_k6_m_usluzna',
    'o7_1_k6_m_poljoprivredna',
    'o7_1_k6_m_zanatlije',
    'o7_1_k6_m_rukovaoci',
    'o7_1_k6_m_jednostavna',
    'o7_1_k6_m_vojna',
] as const

const allMaleFields = [...allFields.filter((e) => e.includes('_m_'))] as const
const allFemaleFields = [...allFields.filter((e) => e.includes('_z_'))] as const

type AllFieldsType = (typeof allFields)[number]

function Step6({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<Form7_1Entity>({ control })
    const fields: AllFieldsType[] = [...allFields]

    const disabledNext = !fields.every((e) => {
        const value = watchedValues[e]
        const isValid = value !== null && value !== undefined && value.toString() !== ''
        return isValid
    })

    const goToNextStep = () => {
        if (!disabledNext) {
            nextStep()
        } else if (adminView) {
            nextStep()
        }
    }
    return (
        <div>
            <h1 className="step-name">6. Запослени и радно ангажовани према главним групама занимања и полу:</h1>
            <ComplexTable
                rows={rows}
                control={control}
                allFemaleFields={[...allFemaleFields]}
                allMaleFields={[...allMaleFields]}
                allFields={[...allFields]}
                header={['', 'Жене', 'Жене %', 'Мушкарци', 'Мушкарци %', 'Укупно', 'Укупно %']}
                errors={errors}
                disabled={adminView}
            />
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step6
