import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Step1 from './Steps/Step1'
import Step2 from './Steps/Step2'
import Step3 from './Steps/Step3'
import Step4 from './Steps/Step4'
import Step5 from './Steps/Step5'
import Step6 from './Steps/Step6'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useUser } from '../../../../auth/UseUser'
import FinishOfFormModal from '../../../../components/Common/FinishOfFormModal'
import LoadingSpinner from '../../../../components/Common/LoadingSpinner'
import { FORM1_TITLE } from '../../../../models/FormTitles'
import Stepper from '../../../../components/Common/Stepper/Stepper'
import { FORM7_NAMES } from '../../../../models/Form7/Form7Names'
import HelpButton from '../../../../components/Common/HelpButton'
import { FORM7_11NAMES, HELP_BUTTON_7_11 } from '../../../../models/Form7/Names/Form7_11Names'
import { Form7_11Entity, NumberOfSteps } from '../../../../models/Form7/Entities/Form7_11.entity'
import Step7 from './Steps/Step7'

function Form7_11Screen() {
    let navigate = useNavigate()
    const [finishModalVisible, setFinishModalVisible] = useState(false)
    const [activeStep, setActiveStep] = useState(0)
    const [loading, setLoading] = useState(false)
    const [formCompleted, setFormCompleted] = useState(false)
    let [searchParams, setSearchParams] = useSearchParams()
    const { user } = useUser()

    const formId = searchParams.get('formId')
    const adminView = user.admin

    const adminUsage = searchParams.get('adminUsage') === '1'

    const {
        handleSubmit,
        control,
        getValues,
        reset,
        setValue,
        register,
        formState: { errors },
    } = useForm<Form7_11Entity>({
        defaultValues: {},
    })

    const stepComponent = () => {
        switch (activeStep) {
            case 0:
                return <Step1 control={control} nextStep={nextStep} previousStep={previousStep} previousButtonDisabled={activeStep === 0} errors={errors} adminView={adminView} />
            case 1:
                return <Step2 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 2:
                return <Step3 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 3:
                return <Step4 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 4:
                return <Step5 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 5:
                return <Step6 control={control} nextStep={nextStep} previousStep={previousStep} errors={errors} adminView={adminView} />
            case 6:
                return <Step7 control={control} nextStep={finish} previousStep={previousStep} errors={errors} adminView={adminView} />
            default:
                return null
        }
    }

    const getUnfinishedForm = async () => {
        setLoading(true)
        // let resp
        // if (!adminView && !formId) {
        //     resp = await getUnfinishedForm1()
        // } else {
        //     console.log('Loading form1 with id: ', formId)

        //     resp = await getForm1(formId)
        // }
        // const form = resp?.data?.form

        // if (resp.status === 200 && resp?.data) {
        //     console.log(resp.data)
        //     Object.keys(form).forEach((key) => {
        //         setValue(key as keyof TblOblast1, form[key])
        //         if (decimalKeys.includes(key)) {
        //             setValue(key as keyof TblOblast1, parseFloat(form[key]))
        //         } else {
        //             setValue(key as keyof TblOblast1, form[key])
        //         }
        //         if (key === 'report') {
        //             setValue('submitted_by.firstName', form[key].submittedBy.firstName)
        //             setValue('submitted_by.lastName', form[key].submittedBy.lastName)
        //             setValue('submitted_by.phoneNumber', form[key].submittedBy.phoneNumber)
        //             setValue('submitted_by.email', form[key].submittedBy.email)
        //             setValue('submitted_by.has_education', form[key].submittedBy.has_education)
        //             setValue('submitted_by.education_date', form[key].submittedBy.education_date)
        //             setValue('submitted_by.education_name', form[key].submittedBy.education_name)
        //             setValue('submitted_by.education_org', form[key].submittedBy.education_org)
        //             setValue('submitted_by.education_teacher', form[key].submittedBy.education_teacher)
        //             setValue('submitted_by.files', form[key].submittedBy.files)
        //         }
        //     })
        //     setFormCompleted(resp.data.form.status !== 0) // Status 0 is for started
        //     setActiveStep(Number(resp.data.currentStep))
        // }
        setLoading(false)
    }

    const onSelectStep = (step: number) => setActiveStep(step)

    const nextStep = async (skip?: boolean) => {
        console.log('get values ', getValues())
        const form = getValues()
        if (!adminView) {
            await save()
            if (activeStep === 0 && form.status === undefined) getUnfinishedForm()
        }
        if (activeStep + 1) {
            setActiveStep(skip ? activeStep + 2 : activeStep + 1)
        }
    }
    const previousStep = () => setActiveStep(activeStep - 1)

    const save = async () => {
        // const resp = await saveForm1(getValues(), (activeStep + 1) as number)
        // if (resp.status == 201) {
        //     setValue('report_id', resp.data.report_id)
        // }
    }

    const finish = async () => {
        if (!adminView) {
            await save()
        }
        setFinishModalVisible(true)
    }

    const onSubmit = async () => {
        // console.log('get values ', getValues())
    }

    const resetValuesAndGoToFirstStep = () => {
        setFinishModalVisible(false)
        setActiveStep(0)
        reset()
        navigate('/')
    }

    return (
        <div className="max-w-[1556px] mx-[auto] px-[40px] pb-[20px]">
            <div className="pt-[40px] pb-[55px] flex flex-row gap-5 items-start ">
                <h1 className="text-[24px] font-light">{FORM7_NAMES[10]}</h1>
                <HelpButton name={HELP_BUTTON_7_11[activeStep]} marginTop={5} />
            </div>
            <div>
                <Stepper
                    formName={FORM7_11NAMES}
                    numberOfSteps={NumberOfSteps}
                    onSelectStep={onSelectStep}
                    activeStep={activeStep}
                    unlockEveryStep={!!adminUsage || adminView || formCompleted}
                />
            </div>
            {!loading ? (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="mt-[55px]">{stepComponent()}</div>
                </form>
            ) : (
                <div className="flex justify-center items-center h-full mt-[100px]">
                    <LoadingSpinner />
                </div>
            )}
            <FinishOfFormModal modalIsOpen={finishModalVisible} closeModal={resetValuesAndGoToFirstStep} infoText={FORM1_TITLE} adminView={adminView} />
        </div>
    )
}

export default Form7_11Screen
