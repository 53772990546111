import '../../../../../assets/styles/SimpleTable.css'
import ActionButtons from '../../../../../components/ActionButtons'
import { Control, useFieldArray, useWatch } from 'react-hook-form'
import Textbox from '../../../../Contact/TextBoxAutoSize'
import PositiveNumberInputCell from '../../../../Form2/Components/PositiveNumberInputCell'
import { Form7_8Entity } from '../../../../../models/Form7/Entities/Form7_8.entity'
import { StepInterface } from './Step1'
import SimpleTable from '../../../../../components/Tables/SimpleTable'
import { Button } from '@mui/material'
import TextInputCell from '../../../../../components/Common/TextInputCell'

import { useState, useEffect } from 'react';

export function Step2({ control, nextStep, previousStep, previousButtonDisabled = false, errors, clearErrors, adminView }: StepInterface) {
    const watchedValues = useWatch<Form7_8Entity>({ control })
    
    const headers = ['Назив профила', 'Опис диспропорције']

    const field_names: Array<"o7_8_k2_profiles_m" | "o7_8_k2_profiles_z"> = ["o7_8_k2_profiles_m", "o7_8_k2_profiles_z"]

    const field_name_m = field_names[0]
    const field_name_z = field_names[1]
    const [emptyArrayError_m, setEmptyArrayError_m] = useState(false)
    const [emptyArrayError_z, setEmptyArrayError_z] = useState(false)
 
    const { fields: fields_m, append: append_m, remove: remove_m } = useFieldArray({
        control,
        name: field_name_m,
    })

    const { fields: fields_z, append: append_z, remove: remove_z } = useFieldArray({
        control,
        name: field_name_z,
    })

    useState(() => {
        if (fields_m.length === 0) {
            append_m({ name: '', description: '' })
        }
        if (fields_z.length === 0) {
            append_z({ name: '', description: '' })
        }
    })

    const handleAddArrayField_m = () => {
        setEmptyArrayError_m(false)
        if (fields_m.length >= 10) return
        append_m({ name: '', description: '' })
    }

    const handleAddArrayField_z = () => {
        setEmptyArrayError_z(false)
        if (fields_z.length >= 10) return
        append_z({ name: '', description: '' })
    }

    const validateEmptyArray_m = () => {
        if (watchedValues[field_name_m]?.length === 0 || watchedValues[field_name_m] === undefined) {
            setEmptyArrayError_m(true)
            return true
        }
        return false
    }

    const validateEmptyArray_z = () => {
        if (watchedValues[field_name_z]?.length === 0 || watchedValues[field_name_z] === undefined) {
            setEmptyArrayError_z(true)
            return true
        }
        return false
    }

    const goToNextStep = () => {
        const disabledNext_m = fields_m.length === 0 || fields_m.some(field => {
            const profiles = watchedValues[field_name_m];
            return profiles === undefined || profiles.length === 0 || profiles.some(profile => !profile.name || !profile.description);
        });
    
        const disabledNext_z = fields_z.length === 0 || fields_z.some(field => {
            const profiles = watchedValues[field_name_z];
            return profiles === undefined || profiles.length === 0 || profiles.some(profile => !profile.name || !profile.description);
        });

        validateEmptyArray_m()
        validateEmptyArray_z()
        
        if (!disabledNext_m && !disabledNext_z) nextStep()
        else if (adminView) nextStep()
    }

    return (
        <div>
            <div className="flex flex-row justify-between">
                <h1>
                    Молим Вас наведите нам до 10 студијских програма у које доминантно уписују особе мушког пола
                </h1>
            </div>
            
            <table className="multiple-input-table mt-[20px]">
                <tr className="multiple-input-table-header">
                    {headers.map((head, index) => (
                        <th className={`multiple-input-table-cell !min-w-[450px]`} key={index}>
                            {head}
                        </th>
                    ))}
                </tr>
                {fields_m.map((field, index) => {
                    return (
                        <div className="flex flex-row" key={index}>
                            <tr key={field.id} className="multiple-input-table-row">
                                <td className="multiple-input-table-cell editable !border-none !min-w-[450px]">
                                <TextInputCell
                                        name={`${field_name_m}.${index}.name`}
                                        control={control}
                                        placeholder="Текст (50 карактера)*"
                                        errors={errors}
                                        isRequired={!adminView}
                                        maxNumberOfCharacter={50}
                                        height={62}
                                        disabled={adminView}
                                    />
                                </td>
                                <td className={`multiple-input-table-cell !border-none !min-w-[450px]`}>
                                    <TextInputCell
                                        name={`${field_name_m}.${index}.description`}
                                        control={control}
                                        placeholder="Текст (150 карактера)*"
                                        errors={errors}
                                        isRequired={!adminView}
                                        maxNumberOfCharacter={150}
                                        height={62}
                                        disabled={adminView}
                                    />
                                </td>
                            </tr>
                            {fields_m.length >= 2 ? (
                                <div className="ml-[20px] flex justify-center items-center ">
                                    <Button color="error" variant="contained" onClick={() => remove_m(index)} disabled={adminView}>
                                        Обриши
                                    </Button>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    )
                })}
                <div className="h-[2px]"> {errors[field_name_m] && <p className="text leading-5 ml-[50px] text-[#d32f2f] text-[0.75rem]">Попуните обавезно поље.</p>}</div>
            </table>

            <div className="flex flex-col mt-[56px]">
                <Button variant="contained" className="max-w-[172px] mt-[56px]" onClick={handleAddArrayField_m} disabled={adminView}>
                    Додај профил
                </Button>
                <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] ">{emptyArrayError_m ? 'Додајте барем једну активност!' : ''}</span>
            </div>

            <div className="flex flex-row justify-between">
                <h1>
                    Молим Вас наведите нам до 10 студијских програма у које доминантно уписују особе женског пола
                </h1>
            </div>
            
            <table className="multiple-input-table mt-[20px]">
                <tr className="multiple-input-table-header">
                    {headers.map((head, index) => (
                        <th className={`multiple-input-table-cell !min-w-[450px]`} key={index}>
                            {head}
                        </th>
                    ))}
                </tr>
                {fields_z.map((field, index) => {
                    return (
                        <div className="flex flex-row" key={index}>
                            <tr key={field.id} className="multiple-input-table-row">
                                <td className="multiple-input-table-cell editable !border-none !min-w-[450px]">
                                <TextInputCell
                                        name={`${field_name_z}.${index}.name`}
                                        control={control}
                                        placeholder="Текст (100 карактера)*"
                                        errors={errors}
                                        isRequired={!adminView}
                                        maxNumberOfCharacter={100}
                                        height={62}
                                        disabled={adminView}
                                    />
                                </td>
                                <td className={`multiple-input-table-cell !border-none !min-w-[450px]`}>
                                    <TextInputCell
                                        name={`${field_name_z}.${index}.description`}
                                        control={control}
                                        placeholder="Текст (150 карактера)*"
                                        errors={errors}
                                        isRequired={!adminView}
                                        maxNumberOfCharacter={150}
                                        height={62}
                                        disabled={adminView}
                                    />
                                </td>
                            </tr>
                            {fields_z.length >= 2 ? (
                                <div className="ml-[20px] flex justify-center items-center ">
                                    <Button color="error" variant="contained" onClick={() => remove_z(index)} disabled={adminView}>
                                        Обриши
                                    </Button>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    )
                })}
                <div className="h-[2px]"> {errors[field_name_z] && <p className="text leading-5 ml-[50px] text-[#d32f2f] text-[0.75rem]">Попуните обавезно поље.</p>}</div>
            </table>

            <div className="flex flex-col mt-[56px]">
                <Button variant="contained" className="max-w-[172px] mt-[56px]" onClick={handleAddArrayField_z} disabled={adminView}>
                    Додај профил
                </Button>
                <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] ">{emptyArrayError_z ? 'Додајте барем једну активност!' : ''}</span>
            </div>
            
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}
