import React from 'react'
import { StepInterface } from './Step1'
import PositiveNumberInputCell from '../../../../Form2/Components/PositiveNumberInputCell'
import ActionButtons from '../../../../../components/ActionButtons'
import { useWatch } from 'react-hook-form'
import { Form7_15Entity } from '../../../../../models/Form7/Entities/Form7_15.entity'

export const Step8 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView, clearErrors }: StepInterface) => {
    const watchedValues = useWatch<Form7_15Entity>({ control })

    const fields: (
        | 'o7_15_k8_radnici'
        | 'o7_15_k8_budzet'
        | 'o7_15_k8_procenat_budzet'
        | 'o7_15_k8_ukupan_budzet'
        | 'o7_15_k8_kampanje_javne'
        | 'o7_15_k8_kampanje_informativne'
    )[] = ['o7_15_k8_radnici', 'o7_15_k8_budzet', 'o7_15_k8_procenat_budzet', 'o7_15_k8_ukupan_budzet', 'o7_15_k8_kampanje_javne', 'o7_15_k8_kampanje_informativne']
    const disabledNext = !fields.every((e) => watchedValues[e] !== null && watchedValues[e] !== undefined && watchedValues[e]?.toString() !== '')

    const goToNextStep = () => {
        if (!disabledNext) nextStep()
        else if (adminView) nextStep()
    }
    return (
        <div>
            <div>
                <h1 className="step-name mb-[10px]">
                    Број здравствених радника/ца који су похађалиобуке о родној равноправности, правима особа из вишеструко дискриминисаних група у години извештавања разврстани по
                    полу
                </h1>
                <div className="max-w-[200px] flex flex-col">
                    <PositiveNumberInputCell
                        name={`o7_15_k8_radnici`}
                        control={control}
                        errors={errors}
                        isRequired={!adminView}
                        height={62}
                        disabled={adminView}
                        form7
                        clearErrors={clearErrors}
                    />

                    <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] mb-10">{errors.o7_15_k8_radnici ? 'Попуните обавезно поље!' : ''}</span>
                </div>
            </div>
            <div>
                <h1 className="step-name mb-[10px]">
                    Износ и процентуални удео буџета који се обезбеђује за превентивне прегледе у односу на укупан бџует здравствену заштиту у Републици Србији, укључујући и
                    обезбеђивање адекватних техничких, материјалних и људских ресурса
                </h1>
                <div className="flex flex-row flex-1 justify-between max-w-[50%]">
                    <div className="max-w-[250px] flex flex-col">
                        <PositiveNumberInputCell
                            name={`o7_15_k8_budzet`}
                            control={control}
                            errors={errors}
                            isRequired={!adminView}
                            decimal
                            height={62}
                            disabled={adminView}
                            form7
                            clearErrors={clearErrors}
                            placeholder="Децимални износ у РСД"
                        />

                        <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] mb-10">{errors.o7_15_k8_budzet ? 'Попуните обавезно поље!' : ''}</span>
                    </div>
                    <div className="max-w-[250px] flex flex-col">
                        <PositiveNumberInputCell
                            name={`o7_15_k8_procenat_budzet`}
                            control={control}
                            errors={errors}
                            isRequired={!adminView}
                            height={62}
                            disabled={adminView}
                            precentage
                            placeholder="Проценат буџета (%)"
                            form7
                            clearErrors={clearErrors}
                        />

                        <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] mb-10">{errors.o7_15_k8_procenat_budzet ? 'Попуните обавезно поље!' : ''}</span>
                    </div>
                    <div className="max-w-[250px] flex flex-col">
                        <PositiveNumberInputCell
                            name={`o7_15_k8_ukupan_budzet`}
                            control={control}
                            errors={errors}
                            isRequired={!adminView}
                            height={62}
                            decimal
                            disabled={adminView}
                            form7
                            clearErrors={clearErrors}
                            placeholder="Укупан буџет"
                        />

                        <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] mb-10">{errors.o7_15_k8_ukupan_budzet ? 'Попуните обавезно поље!' : ''}</span>
                    </div>
                </div>
                <div>
                    <h1 className="step-name mb-[10px]">
                        Број јавних кампања за ширу јавност о значају заштите здравља, спречавања болести и побољшања квалитета живота, упражњавањем здравих стилова живота, спорта
                        и рекреације
                    </h1>
                    <div className="max-w-[200px] flex flex-col">
                        <PositiveNumberInputCell
                            name={`o7_15_k8_kampanje_javne`}
                            control={control}
                            errors={errors}
                            isRequired={!adminView}
                            height={62}
                            disabled={adminView}
                            form7
                            clearErrors={clearErrors}
                        />

                        <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] mb-10">{errors.o7_15_k8_kampanje_javne ? 'Попуните обавезно поље!' : ''}</span>
                    </div>
                </div>
                <div>
                    <h1 className="step-name mb-[10px]">
                        Број информативних кампања за информисање грађана и грађанки о предностима ране превенције, савременим облицима контрацепције, важности репродуктивног
                        здравља
                    </h1>
                    <div className="max-w-[200px] flex flex-col">
                        <PositiveNumberInputCell
                            name={`o7_15_k8_kampanje_informativne`}
                            control={control}
                            errors={errors}
                            isRequired={!adminView}
                            height={62}
                            disabled={adminView}
                            form7
                            clearErrors={clearErrors}
                        />

                        <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] mb-10">{errors.o7_15_k8_kampanje_informativne ? 'Попуните обавезно поље!' : ''}</span>
                    </div>
                </div>
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}
