import React, { useEffect, useState } from 'react'

import { useForm } from 'react-hook-form'

import Step1 from './Steps/Step1'
import Step2 from './Steps/Step2'

import { TblOblast1 } from '../../../models/Form1/Form1.entity'
import { FORM1_TITLE } from '../../../models/FormTitles'
import { FORM1_NAMES } from '../../../models/Form1/Form1Names'
import Stepper from '../../../components/Common/Stepper/Stepper'
import Filter from '../Common/Filter'
import Step3 from './Steps/Step3'
import Step4 from './Steps/Step4'
import Step5 from './Steps/Step5'
import Step6 from './Steps/Step6'
import Step7 from './Steps/Step7'
import Step8 from './Steps/Step8'
import Step9 from './Steps/Step9'
import Step10 from './Steps/Step10'
import Step11 from './Steps/Step11'
import Step12 from './Steps/Step12'
import Step13 from './Steps/Step13'
import Step14 from './Steps/Step14'
import Step15 from './Steps/Step15'
import Step19 from './Steps/Step19'
import Step18 from './Steps/Step18'
import Step17 from './Steps/Step17'
import Step16 from './Steps/Step16'
import { loadCategoriesTree } from '../../../server/server'

function Form1AnalyticsScreen() {
    const [activeStep, setActiveStep] = useState(0)
    const [loading, setLoading] = useState(false)
    const [formCompleted, setFormCompleted] = useState(false)
    const [countType, setCountType] = useState<'total' | 'average'>('total')
    const [categories, setCategories] = useState<any[]>([])
    const [selectedCategories, setSelectedCategories] = useState<any[]>([])
    const [selectedSubcategories, setSelectedSubcategories] = useState<any[]>([])

    const {
        handleSubmit,
        control,
        getValues,
        reset,
        setValue,
        register,
        formState: { errors },
    } = useForm<TblOblast1>({
        defaultValues: {},
    })

    useEffect(() => {
        loadCategories()
    }, [])

    const loadCategories = async () => {
        const resp = await loadCategoriesTree()
        if (resp.status === 200) {
            setCategories(resp.data)
        }
    }

    const onSelectStep = (step: number) => setActiveStep(step)

    const nextStep = async () => {
        if (activeStep + 1) {
            setActiveStep(activeStep + 1)
        }
    }
    const previousStep = () => setActiveStep(activeStep - 1)

    const stepComponent = () => {
        switch (activeStep) {
            case 0:
                return (
                    <Step1
                        control={control}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        previousButtonDisabled={activeStep === 0}
                        setValue={setValue}
                        countType={countType}
                        selectedCategories={selectedCategories}
                        selectedSubCategories={selectedSubcategories}
                    />
                )
            case 1:
                return (
                    <Step2
                        control={control}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        setValue={setValue}
                        countType={countType}
                        selectedCategories={selectedCategories}
                        selectedSubCategories={selectedSubcategories}
                    />
                )
            case 2:
                return (
                    <Step3
                        control={control}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        setValue={setValue}
                        countType={countType}
                        selectedCategories={selectedCategories}
                        selectedSubCategories={selectedSubcategories}
                    />
                )
            case 3:
                return (
                    <Step4
                        control={control}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        setValue={setValue}
                        countType={countType}
                        selectedCategories={selectedCategories}
                        selectedSubCategories={selectedSubcategories}
                    />
                )
            case 4:
                return (
                    <Step5
                        control={control}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        setValue={setValue}
                        countType={countType}
                        selectedCategories={selectedCategories}
                        selectedSubCategories={selectedSubcategories}
                    />
                )
            case 5:
                return (
                    <Step6
                        control={control}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        setValue={setValue}
                        countType={countType}
                        selectedCategories={selectedCategories}
                        selectedSubCategories={selectedSubcategories}
                    />
                )
            case 6:
                return (
                    <Step7
                        control={control}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        setValue={setValue}
                        countType={countType}
                        selectedCategories={selectedCategories}
                        selectedSubCategories={selectedSubcategories}
                    />
                )
            case 7:
                return (
                    <Step8
                        control={control}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        setValue={setValue}
                        countType={countType}
                        selectedCategories={selectedCategories}
                        selectedSubCategories={selectedSubcategories}
                    />
                )
            case 8:
                return (
                    <Step9
                        control={control}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        setValue={setValue}
                        countType={countType}
                        selectedCategories={selectedCategories}
                        selectedSubCategories={selectedSubcategories}
                    />
                )
            case 9:
                return (
                    <Step10
                        control={control}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        setValue={setValue}
                        countType={countType}
                        selectedCategories={selectedCategories}
                        selectedSubCategories={selectedSubcategories}
                    />
                )
            case 10:
                return (
                    <Step11
                        control={control}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        setValue={setValue}
                        countType={countType}
                        selectedCategories={selectedCategories}
                        selectedSubCategories={selectedSubcategories}
                    />
                )
            case 11:
                return (
                    <Step12
                        control={control}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        setValue={setValue}
                        countType={countType}
                        selectedCategories={selectedCategories}
                        selectedSubCategories={selectedSubcategories}
                    />
                )
            case 12:
                return (
                    <Step13
                        control={control}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        setValue={setValue}
                        countType={countType}
                        selectedCategories={selectedCategories}
                        selectedSubCategories={selectedSubcategories}
                    />
                )
            case 13:
                return (
                    <Step14
                        control={control}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        setValue={setValue}
                        countType={countType}
                        selectedCategories={selectedCategories}
                        selectedSubCategories={selectedSubcategories}
                    />
                )
            case 14:
                return (
                    <Step15
                        control={control}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        setValue={setValue}
                        countType={countType}
                        selectedCategories={selectedCategories}
                        selectedSubCategories={selectedSubcategories}
                    />
                )
            case 15:
                return (
                    <Step16
                        control={control}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        setValue={setValue}
                        countType={countType}
                        selectedCategories={selectedCategories}
                        selectedSubCategories={selectedSubcategories}
                    />
                )
            case 16:
                return (
                    <Step17
                        control={control}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        setValue={setValue}
                        countType={countType}
                        selectedCategories={selectedCategories}
                        selectedSubCategories={selectedSubcategories}
                    />
                )
            case 17:
                return (
                    <Step18
                        control={control}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        setValue={setValue}
                        countType={countType}
                        selectedCategories={selectedCategories}
                        selectedSubCategories={selectedSubcategories}
                    />
                )
            case 18:
                return (
                    <Step19
                        control={control}
                        nextStep={nextStep}
                        previousStep={previousStep}
                        setValue={setValue}
                        countType={countType}
                        selectedCategories={selectedCategories}
                        selectedSubCategories={selectedSubcategories}
                    />
                )

            default:
                return null
        }
    }

    const selectCategory = (categories: any[]) => {
        setSelectedCategories(categories ?? [])
        if (categories.length === 0) {
            setSelectedSubcategories([])
        }
    }

    return (
        <div className="max-w-[1556px] mx-[auto] px-[40px] pb-[20px]">
            <div className="pt-[40px] pb-[55px]">
                <h1 className="text-[24px] font-light">Аналитика за {FORM1_TITLE}</h1>
            </div>
            <div>
                <Stepper formName={FORM1_NAMES} numberOfSteps={19} onSelectStep={onSelectStep} activeStep={activeStep} unlockEveryStep={true} />
                <Filter
                    categories={categories}
                    selectCategory={selectCategory}
                    selectedCategories={selectedCategories}
                    selectedSubcategories={selectedSubcategories}
                    setSelectedSubcategories={setSelectedSubcategories}
                    countType={countType}
                    setCountType={setCountType}
                />
            </div>
            <div className="mt-[20px]">{stepComponent()}</div>
        </div>
    )
}

export default Form1AnalyticsScreen
