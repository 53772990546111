import React, { useEffect, useState } from 'react'
import { Button, TextField } from '@mui/material'
import { Entity, PibEntity } from './types'

interface PibListProps {
    entities: PibEntity[]
    onSelect: (id: string) => void
    type?: 'category' | 'subcategory' | 'entity' | 'pib'
    onAddNewPib?: (pib: string) => void
    showAddNewPib?: boolean
}

export const PibList: React.FC<PibListProps> = ({ entities, onSelect, type, onAddNewPib, showAddNewPib }) => {
    const [searchTerm, setSearchTerm] = useState('')
    const [newPib, setNewPib] = useState('')

    useEffect(() => {
        setSearchTerm('')
    }, [type])

    const handleAddNewPib = async () => {
        await onAddNewPib?.(newPib)
        setNewPib('')
    }

    return (
        <div className="flex-1 h-full  max-h-[600px] min-h-[600px] flex flex-col ">
            <TextField label="Претражи ПИБ" variant="outlined" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} style={{ width: '100%', marginBottom: 10 }} />
            <div className="flex flex-row items-center gap-[10px] justify-center mb-[10px]" style={{ opacity: showAddNewPib ? 100 : 0 }}>
                <TextField
                    label="Унос новог ПИБа"
                    variant="outlined"
                    value={newPib}
                    onChange={(e) => setNewPib(e.target.value)}
                    style={{ width: '100%' }}
                    disabled={!showAddNewPib}
                />
                <Button variant="contained" color="inherit" disabled={!showAddNewPib || !newPib} onClick={handleAddNewPib}>
                    Додај ПИБ
                </Button>
            </div>
            <div className=" flex-1 border overflow-y-auto border-[gray] rounded-md gap-[5px] flex flex-col p-[10px]">
                {entities
                    .filter((e) => e?.pib?.toLowerCase()?.includes(searchTerm?.toLowerCase()))
                    .map((entity) => (
                        <Button variant="contained" color={entity.selected ? 'primary' : 'inherit'} key={entity.id} onClick={() => onSelect(entity.id)}>
                            <div>ПИБ: {entity.pib}</div>
                        </Button>
                    ))}
            </div>
        </div>
    )
}
