import '../../../../../assets/styles/SimpleTable.css'
import ActionButtons from '../../../../../components/ActionButtons'
import { Control, useWatch } from 'react-hook-form'
import Textbox from '../../../../Contact/TextBoxAutoSize'
import PositiveNumberInputCell from '../../../../Form2/Components/PositiveNumberInputCell'
import { Form7_9Entity } from '../../../../../models/Form7/Entities/Form7_9.entity'
import SimpleTable from '../../../../../components/Tables/SimpleTable'

export interface StepInterface {
    control?: Control<Form7_9Entity>
    nextStep: () => void
    previousStep: () => void
    previousButtonDisabled?: boolean
    errors?: any
    adminView?: boolean
    setValue?: any
    clearErrors?: any
}

export interface RowInterface {
    label: string
    field: keyof Form7_9Entity
}

const rows: RowInterface[] = [
    { label: 'Мушкарци', field: 'o7_9_k1_student_m_budzet' },
    { label: 'Жене', field: 'o7_9_k1_student_z_budzet' },
]
const fields: ('o7_9_k1_student_m_budzet' | 'o7_9_k1_student_z_budzet')[] = ['o7_9_k1_student_m_budzet', 'o7_9_k1_student_z_budzet']

export function Step1({ control, nextStep, previousStep, previousButtonDisabled = false, errors, clearErrors, adminView }: StepInterface) {
    const watchedValues = useWatch<Form7_9Entity>({ control })
    const disabledNext = watchedValues?.o7_9_k1_acts === undefined || typeof watchedValues.o7_9_k1_acts !== 'number' ||
                        watchedValues?.o7_9_k1_research === undefined || typeof watchedValues.o7_9_k1_research !== 'number' ||
                        watchedValues?.o7_9_k1_student_m_budzet === undefined || typeof watchedValues.o7_9_k1_student_m_budzet !== 'number' ||
                        watchedValues?.o7_9_k1_student_z_budzet === undefined || typeof watchedValues.o7_9_k1_student_z_budzet !== 'number' ||
                        watchedValues?.o7_9_k1_studies_in_books === undefined || typeof watchedValues.o7_9_k1_studies_in_books !== 'number';

    const goToNextStep = () => {
        if (!disabledNext && !errors?.o4_k7_procenat) nextStep()
        else if (adminView) nextStep()
    }

    const inputConfigs = [
        {
            name: "o7_9_k1_acts",
            label: "Број подзаконских аката који садрже родно осетљиве стандарде и поступке за акредитацију студијских програма и високошколских установа."
        },
        {
            name: "o7_9_k1_research",
            label: "Број студија и анализа у којима су утврђени и кориговани родно стереотипни и дискриминаторни садржаји у силабусима и универзитетским уџбеницима."
        },
        {
            name: "o7_9_k1_studies_in_books",
            label: "Број научно–истраживачких пројеката у области родне равноправности финансираних из буџета у години извештавања."
        },
    ];

    return (
        <div>
            <div>
                {inputConfigs.map((config, index) => (
                    <>
                        <h1 className="step-name mb-[20px] ">{config.label}</h1>
                        <div className=" flex flex-row gap-5 mb-[40px]">
                            <div className={`w-[140px] h-[50px] flex justify-center border-none rounded-md`}>
                                <PositiveNumberInputCell
                                    name={config.name}
                                    control={control}
                                    errors={errors}
                                    isRequired={!adminView}
                                    height={52}
                                    disabled={adminView}
                                />
                            </div>
                        </div>
                    </>
                ))}
            </div>
            <h1 className="step-name mb-[0px] ">Број студенткиња и студената на родним студијама чије се образовање финансира из буџета.</h1>
            <div className="flex flex-row gap-2 mb-[20px]">
                <SimpleTable control={control} header={['Пол', 'Број', 'Проценат']} rows={rows} fields={fields} errors={errors} disabled={adminView} />
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}
