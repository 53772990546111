export const FORM7_12NAMES = [
    '1. Број реализованих обука о родној равноправности, смањењу родних стереотипа и равноправном учешћу жена и мушкараца у јавном и политичком животу за представнике/це институција јавне власти у години извештавања',
    '2. Број обучених представника/ца институција јавне власти о родној равноправности, смањењу родних стереотипа и равноправном учешћу жена и мушкараца у јавном и политичком животу, разврстано по полу',
    '3. Број жртава трговине људима, разврстано према полу, узрасту, и облику експлоатације',
    '4. Проценат/број кривичних пријава/оптужења за кривичнa дела са елементима насиља, посебно за кривична дела сексуалног насиља и трговине људима у години извештавања.',
    '5. Број ОЦД које су пружају услуге жртвама насиља, активне су у заштити права жртава, које се залажу за измене дискриминаторих друштвених норми и родних стереотипа и надгледају примену међународних стандарда у заштити жртава родно заснованог насиља. ',
    '6. Број превентивних програма/интервенција рада са учиниоцима насиља у циљу спречавања насиља',
    '7. Поштовани, кликом на заврши и пошаљи потврђујете да сте унели све потребне информације у дефинисана поља',
]
export const HELP_BUTTON_7_12 = ['o7_12-k1', 'o7_12-k2', 'o7_12-k3', 'o7_12-k4', 'o7_12-k5', 'o7_12-k6', 'o7_12-k7']
