import { useEffect, useState } from 'react'
import { useFieldArray, useWatch } from 'react-hook-form'
import { Button } from '@mui/material'
import ActionButtons from '../../../../../components/ActionButtons'
import { Form7_11Entity } from '../../../../../models/Form7/Entities/Form7_11.entity'
import { StepInterface } from './Step1'
import Form7_11_k4Table from '../../../Components/Form7_11_k4Table'

export interface RowInterface {
    label: string
    subtitle: string
    field: number
}
const arrayName = 'o7_11_k5_organizacije'

const HEADER_LIST = ['Број организација', 'Врста услуге']

function Step5({ control, nextStep, previousStep, previousButtonDisabled = false, errors, setValue, adminView }: StepInterface) {
    const watchedValues = useWatch<Form7_11Entity>({ control })

    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control,
        name: arrayName,
    })

    useEffect(() => {
        if (!fields.length) append([{ broj: undefined }])
    }, [])

    const handleAddField = () => {
        append({ broj: undefined })
    }

    const validateFields = (): boolean => {
        if (adminView) {
            return true
        }
        const newMap = watchedValues.o7_11_k5_organizacije?.map((field) => !!field.broj && !!field.vrsta)
        return newMap?.includes(false) ? true : false
    }

    const disabledNext = validateFields()
    const disabledAddMore = validateFields()

    const goToNexStep = () => {
        if (!disabledNext) {
            nextStep()
        } else if (adminView) {
            nextStep()
        }
    }
    return (
        <div>
            <h1 className="step-name mb-1">
                5. Број ОЦД које су пружају услуге жртвама насиља, активне су у заштити права жртава, које се залажу за измене дискриминаторих друштвених норми и родних стереотипа
                и надгледају примену међународних стандарда у заштити жртава родно заснованог насиља:
            </h1>

            <Form7_11_k4Table
                name="o7_11_k5_organizacije"
                fields={fields}
                remove={remove}
                control={control}
                header={HEADER_LIST}
                errors={errors}
                disabled={adminView}
                showPercentage={false}
            />
            <p className="ml-5 min-h-[30px] text-textError text-[0.75rem] text leading-[30px]">{errors['o7_11_k5_organizacije'] ? 'Попуните обавезно поље.' : ''}</p>

            <Button variant="contained" onClick={handleAddField} disabled={disabledAddMore}>
                Додај још
            </Button>

            <ActionButtons nextButton={goToNexStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step5
