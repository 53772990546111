import React, { useState } from 'react'
import { Control, useWatch } from 'react-hook-form'
import ComplexTable from '../../../../../components/Tables/ComplexTable'
import ActionButtons from '../../../../../components/ActionButtons'
import PositiveNumberInputCell from '../../../../Form2/Components/PositiveNumberInputCell'
import { calculateDifference, percentage, sumValues } from '../../../../../utils/mathUtils'
import { Form7_12Entity } from '../../../../../models/Form7/Entities/Form7_12.entity'
import { StepInterface } from './Step1'

interface RowInterface {
    label: string
    fields: (keyof Form7_12Entity)[]
}

const rowsTable: RowInterface[] = [
    { label: 'ТГ-1', fields: ['o7_12_k2_z_tg_1', 'o7_12_k2_m_tg_1'] },
    { label: 'ТГ', fields: ['o7_12_k2_z_tg', 'o7_12_k2_m_tg'] },
]

const header: string[] = ['', 'Жене', 'Мушкарци', 'Разлика %']

const allFieldsTable = ['o7_12_k2_z_tg_1', 'o7_12_k2_z_tg', 'o7_12_k2_m_tg_1', 'o7_12_k2_m_tg'] as const

const allFemaleFieldsTable = [...allFieldsTable.filter((e) => e.includes('_z_'))] as const
const allMaleFieldsTable = [...allFieldsTable.filter((e) => e.includes('_m_'))] as const

type AllFieldsType = (typeof allFieldsTable)[number]

function Step2({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<Form7_12Entity>({ control })
    const fieldsTable: AllFieldsType[] = [...allFieldsTable]
    const [disabled, setDisabled] = useState(false)
    const hasRowErrors = rowsTable.some((row) => row.fields.some((field) => errors?.[field]))

    const disabledNext = !fieldsTable.every((e) => {
        const value = watchedValues[e]
        const isValid = value !== null && value !== undefined && value.toString() !== ''
        return isValid
    })

    const goToNextStep = () => {
        if (!disabledNext) {
            nextStep()
        } else if (adminView) {
            nextStep()
        }
    }
    return (
        <div>
            <h1 className="step-name">1.Просечне зараде запослених према полу (у хиљадама РСД):</h1>
            <table className="complex-table mt-[30px]">
                <tr className="complex-table-header">
                    {header.map((head, index) => (
                        <th className="complex-table-cell" style={{ width: index === 0 ? 450 : 140 }} key={index}>
                            {head}
                        </th>
                    ))}
                </tr>
                {rowsTable.map((row, index) => (
                    <tr className="complex-table-row" key={index}>
                        <td className="complex-table-cell  disabled" style={{ width: 450 }}>
                            {row.label}
                        </td>
                        <td className="complex-table-cell editable !border-none" style={{ border: 0, padding: 0 }}>
                            <PositiveNumberInputCell
                                name={row.fields[0] as string}
                                control={control}
                                decimal={true}
                                isMultipleTable={true}
                                errors={errors}
                                isRequired={!disabled}
                                disabled={disabled}
                            />
                        </td>
                        <td className="complex-table-cell editable !border-none" style={{ border: 0, padding: 0 }}>
                            <PositiveNumberInputCell
                                name={row.fields[1] as string}
                                control={control}
                                decimal={true}
                                isMultipleTable={true}
                                errors={errors}
                                isRequired={!disabled}
                                disabled={disabled}
                            />
                        </td>
                        <td className="complex-table-cell disabled">{percentage(watchedValues[row.fields[0]], watchedValues[row.fields[1]], true)}</td>
                    </tr>
                ))}
                <tr className="complex-table-row">
                    <td className="complex-table-cell  disabled" style={{ width: 450 }}>
                        Разлика ТГ-1 до ТГ
                    </td>
                    <td className="complex-table-cell  disabled">{percentage(watchedValues['o7_12_k2_z_tg_1'], watchedValues['o7_12_k2_z_tg'], true)}</td>
                    <td className="complex-table-cell  disabled">{percentage(watchedValues['o7_12_k2_m_tg_1'], watchedValues['o7_12_k2_m_tg'], true)}</td>
                    <td className="complex-table-cell disabled">{}</td>
                </tr>
                <div className="h-[20px]"> {hasRowErrors && <p className="text leading-5 ml-[50px] text-[#d32f2f] text-[0.75rem]">Попуните обавезно поље.</p>}</div>
            </table>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step2
