import { useEffect, useState } from 'react'
import { useFieldArray, useWatch } from 'react-hook-form'
import { Button } from '@mui/material'
import RiskDiagram from '../../../assets/images/RiskDiagram.png'
import { StepInterface } from './Step1'
import DocumentsTable from '../../../Components/DocumentsTable'
import ActionButtons from '../../../../../components/ActionButtons'
import { Form7_10Entity } from '../../../../../models/Form7/Entities/Form7_10.entity'
import UploadFile from '../../../../../components/UploadFile'
export interface RowInterface {
    label: string
    subtitle: string
    field: number
}
const fileTypes = ['PDF']
const arrayName = 'o7_10_k5_nazivi'

const HEADER_LIST = ['Редни број', 'Назив документа', 'Врста документа', 'Линк ако постоји']

function Step5({ control, nextStep, previousStep, previousButtonDisabled = false, errors, setValue, adminView }: StepInterface) {
    const watchedValues = useWatch<Form7_10Entity>({ control })

    const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
        control,
        name: arrayName,
    })

    useEffect(() => {
        if (!fields.length) append([{ naziv: '', vrsta: '', link: '' }])
    }, [])

    const handleAddField = () => {
        append({ naziv: '', vrsta: '', link: '' })
    }

    const validateFields = (): boolean => {
        if (adminView) {
            return true
        }
        const newMap = watchedValues.o7_10_k5_nazivi?.map((field) => field.naziv != '' && !!field.vrsta)
        return newMap?.includes(false) ? true : false
    }

    const disabledNext = validateFields()
    const disabledAddMore = validateFields()

    const goToNexStep = () => {
        if (!disabledNext) {
            nextStep()
        } else if (adminView) {
            nextStep()
        }
    }
    const nestedList = fields.map((obj) => Object.keys(obj))
    return (
        <div>
            <h1 className="step-name mb-1">5. Уколико имате додатне документе релевантне за извештавање молимо да их приложите:</h1>
            <ul className="list-disc list-inside space-y-1 ">
                <li>Увид у усвојене или измењене прописе и њихову усклађеност са међународним стандардима (ЕУ, УН, СЕ) за прописе који су родно уподобљавани</li>
                <li>Извештаји пружалаца бесплатне правне помоћи, разврстани по полу, територијалној припадности</li>
                <li>Списак пружалаца бесплатне правне помоћи и бесплатне правне подршке разврстаних по полу</li>
                <li>Извештаји Министарства правде на годишњем нивоу, увид у законе и подзаконске акте</li>
                <li>Обједињене анализе, студије и евалуације института</li>
                <li>Извештаји Министарства правде о обезбеђивању стандарда доступности судова и тужилаштва</li>
                <li>Извештаји Високог савета судства и Државног већа тужилаца</li>
                <li>Независни извештаји, студије и евалуације</li>
            </ul>
            <DocumentsTable name={arrayName} fields={nestedList} remove={remove} control={control} header={HEADER_LIST} errors={errors} disabled={adminView} />
            <p className="ml-5 min-h-[30px] text-textError text-[0.75rem] text leading-[30px]">{errors['o7_10_k5_nazivi'] ? 'Попуните обавезно поље.' : ''}</p>
            <div className="flex flex-row items-center gap-10 mt-5">
                <Button variant="contained" onClick={handleAddField} disabled={disabledAddMore}>
                    Додај још
                </Button>
                <p>* линк није обавезан</p>
            </div>
            <UploadFile control={control} name={'o7_10_k5_files'} disabled={adminView} fileTypes={fileTypes} errors={errors} isRequired={true} />

            <ActionButtons nextButton={goToNexStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step5
