import { Checkbox } from '@mui/material'
import React from 'react'
import { Controller, useWatch } from 'react-hook-form'
import { Form7_5Entity } from '../../../models/Form7/Entities/Form7_5.entity'

interface CheckboxComponentInterface {
    control: any
    errors: any
    name: string
    isRequired: any
    text: string
    onValidate?: () => void
}

const CheckboxComponent = ({ control, errors, name, isRequired, text, onValidate }: CheckboxComponentInterface) => {
    return (
        <div>
            <Controller
                name={name}
                control={control}
                rules={{ required: isRequired }}
                render={({ field }: any) => (
                    <Checkbox
                        size="large"
                        checked={field.value}
                        onChange={(e: any) => {
                            field.onChange(e.target.checked)
                            console.log(`Checkbox ${name} promenjen na: ${e.target.checked}`) // Debug log
                            if (onValidate) onValidate()
                        }}
                        sx={{
                            color: errors[name] ? '#d32f2f' : '#A2CEFF',
                            '&.Mui-checked': {
                                color: errors[name] ? '#d32f2f' : '#A2CEFF',
                            },
                        }}
                    />
                )}
            />
            <span className={`text-[14px] leading-[20px] font-light max-w-[520px] ${errors[name] && 'text-[#d32f2f]'}`}>{text}</span>
        </div>
    )
}

export default CheckboxComponent
