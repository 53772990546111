import React from 'react'
import { Control, useWatch } from 'react-hook-form'
import TextInputCell from '../../../components/Common/TextInputCell'
import { ReactComponent as Delete } from '../../../assets/svg/Delete.svg'
import { Button } from '@mui/material'
import { getPercentage } from '../../../utils/mathUtils'
import { Form7_11Entity } from '../../../models/Form7/Entities/Form7_11.entity'
import PositiveNumberInputCell from '../../Form2/Components/PositiveNumberInputCell'

interface RiskTableInterface {
    control: Control<Form7_11Entity> | undefined
    fields: any[]
    remove: any
    header: string[]
    errors?: any
    disabled?: boolean
    name: string
    showPercentage?: boolean
}

function Form7_11_k4Table({ name, control, fields, remove, header, errors, disabled, showPercentage = true }: RiskTableInterface) {
    const watchedValues = useWatch<Form7_11Entity | any>({ control })

    const total = watchedValues[`${name}`]?.reduce((sum: any, item: any) => sum + (!!item.broj ? item.broj : 0), 0)

    return (
        <table className="simple-table mt-[50px] gap-[6px]">
            <tr className="simple-table-header">
                {header.map((head, index) => (
                    <th className={`${index == 0 ? (showPercentage ? 'w-[274px]' : 'w-[126px]') : 'w-[800px]'}  h-[54px]`} key={index}>
                        {head}
                    </th>
                ))}

                <td className=" pl-3 flex flex-row gap-6 items-center invisible" style={{ border: 0 }}>
                    {fields.length > 1 && (
                        <Button className="w-fit cursor-pointer" disabled={disabled}>
                            <Delete />
                        </Button>
                    )}
                </td>
            </tr>
            {fields.map((field, index) => (
                <tr className="simple-table-row" key={field.id}>
                    <td className={`editable w-[126px] h-[54px] `} style={{ padding: 0, border: 0 }}>
                        <PositiveNumberInputCell
                            name={`${name}.${index}.broj`}
                            control={control}
                            isMultipleTable={true}
                            errors={errors}
                            isRequired={!disabled}
                            disabled={disabled}
                            maxW={400}
                            isArray={true}
                            min={1}
                        />
                    </td>
                    {showPercentage && (
                        <td className="complex-table-cell disabled  min-w-[136px] h-[54px]">
                            {getPercentage(!!watchedValues[`${name}`] ? watchedValues[`${name}`][index]?.broj : 0, total)}%
                        </td>
                    )}
                    <td className=" editable w-[800px] h-[54px]" style={{ padding: 0, border: 0 }}>
                        <TextInputCell
                            name={`${name}.${index}.vrsta`}
                            control={control}
                            placeholder="Tекст (100 карактера)"
                            disabled={disabled}
                            maxNumberOfCharacter={100}
                            errors={errors}
                            isRequired={!disabled}
                        />
                    </td>
                    <td className=" pl-3 flex flex-row gap-6 items-center " style={{ border: 0 }}>
                        {/* <div className="w-fit cursor-pointer">
                            <Edit />
                        </div> */}
                        {fields.length > 1 && !disabled && (
                            <Button className="w-fit cursor-pointer" onClick={() => remove(index)}>
                                <Delete />
                            </Button>
                        )}
                    </td>
                </tr>
            ))}
        </table>
    )
}

export default Form7_11_k4Table
