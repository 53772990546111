import React, { useState } from 'react'
import { Control, useFieldArray, useWatch } from 'react-hook-form'
import ActionButtons from '../../../../../components/ActionButtons'
import UploadFile from '../../../../../components/UploadFile'
import { Button } from '@mui/material'
import FileDescriptionTable from '../../../Components/FileDescriptionTable'
import { Form7_15Entity } from '../../../../../models/Form7/Entities/Form7_15.entity'
import PositiveNumberInputCell from '../../../../Form2/Components/PositiveNumberInputCell'
import { isNotEmpty } from '../../../../../utils/Validation'

export interface StepInterface {
    control?: Control<Form7_15Entity>
    nextStep: () => void
    previousStep: () => void
    previousButtonDisabled?: boolean
    errors?: any
    adminView?: boolean
    setValue?: any
    clearErrors?: any
}

export const Step7 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView, clearErrors }: StepInterface) => {
    const watchedValues = useWatch<Form7_15Entity>({ control })
    const [emptyArrayError, setEmptyArrayError] = useState(false)
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'o7_15_k7_fileDescription',
    })
    const name = 'o7_15_k7_fileDescription'

    let disabledNext = watchedValues[name]?.length === 0

    fields.forEach((item, index) => {
        const { fileName, type, link } = watchedValues[name]?.[index] || {}

        if (fileName === undefined || fileName === null || fileName === '') {
            disabledNext = true
        } else if (type === undefined || type === null || type === '') {
            disabledNext = true
        } else if (link === undefined || link === null || link === '') {
            disabledNext = true
        } else disabledNext = false
    })

    const header = ['Бр.', 'Назив документа', 'Врста', 'Линк ако постоји']

    const handleAddArrayField = () => {
        setEmptyArrayError(false)
        append({ fileName: '', type: '', link: '' })
    }

    const validateEmptyArray = () => {
        if (watchedValues[name]?.length === 0 || watchedValues[name] === undefined) {
            setEmptyArrayError(true)
            return true
        }
        return false
    }

    const goToNextStep = () => {
        if (!disabledNext && !validateEmptyArray() && isNotEmpty(watchedValues.o7_15_k7_akti)) nextStep()
        else if (adminView) nextStep()
    }
    return (
        <div>
            <div>
                <h1 className="step-name mb-[10px]">Број измењених и усвојених законских и подзаконских аката који обезбеђују приступ и квалитет здравственим услугама</h1>
                <div className="max-w-[200px] flex flex-col">
                    <PositiveNumberInputCell
                        name={`o7_15_k7_akti`}
                        control={control}
                        errors={errors}
                        isRequired={!adminView}
                        height={62}
                        disabled={adminView}
                        form7
                        clearErrors={clearErrors}
                    />

                    <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] mb-10">{errors.o7_15_k7_akti ? 'Попуните обавезно поље!' : ''}</span>
                </div>
            </div>
            <div className="flex flex-col justify-between">
                <h1 className="step-name mb-[10px]">Молимо Вас да приложите измењене акте које сте донели у години извештавања</h1>
            </div>
            <FileDescriptionTable header={header} fields={fields} control={control} errors={errors} name={name} adminView={adminView} remove={remove} />

            <div className="flex flex-col mt-[56px]">
                <Button variant="contained" className="max-w-[172px] mt-[56px]" onClick={handleAddArrayField} disabled={adminView}>
                    Додај датотеку
                </Button>
                <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] ">{emptyArrayError ? 'Додајте барем једну активност!' : ''}</span>
            </div>

            <div className="mt-10 gap-10">
                <h1>Уколико сте приложили доступан линк није потребно да прилажете документ</h1>
                <UploadFile control={control} name={'o7_15_k7_file'} disabled={adminView} />
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}
