import React, { useMemo, useState } from 'react'
import { ReactComponent as Expand } from '../../../assets/svg/ExpandIcon.svg'
import ExpandButton from './ExpandButton'
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { CategoryList } from '../../Categories/CategoriesList'
import { FormSelector } from '../../Categories/FormSelector'
import { CategorySelector } from './CategorySelector'

interface FilterProps {
    categories: any[]
    selectCategory: (category: any) => void
    selectedCategories: any[]
    setSelectedSubcategories: (category: any) => void
    selectedSubcategories: any[]
    countType: 'total' | 'average'
    setCountType: (type: 'total' | 'average') => void
}

function Filter({ categories, countType, selectCategory, selectedCategories, setCountType, selectedSubcategories, setSelectedSubcategories }: FilterProps) {
    const [expanded, setExpanded] = useState(false)

    const subCategories = useMemo(() => {
        const subCategories = selectedCategories.map((e) => e.children).flat()
        return subCategories
    }, [selectedCategories])

    return (
        <div className="mt-[10px] ">
            <div className="flex flex-row items-center gap-[10px]">
                <h2 className="text-[18px] font-normal max-w-[800px]">Филтер за аналитику</h2>
                <ExpandButton open={expanded} onClick={() => setExpanded(!expanded)} />
            </div>
            <div
                className={`overflow-hidden transition-all   rounded-md mt-[10px] justify-self-start border ${
                    expanded ? 'max-h-[1000px] h-auto border-[#A2CEFF] bg-[#F5F5F5] ' : 'max-h-0 border-white'
                } ${expanded ? 'opacity-100' : 'opacity-0'}`}
            >
                <div className="flex flex-col gap-[5px] p-[10px]">
                    <div className="flex flex-row items-center gap-[5px]">
                        <label htmlFor="" className="font-normal">
                            Генериши резултате на основу:
                        </label>
                        <div className="flex gap-[10px]">
                            <label>
                                <RadioGroup row name="categoryType" value={countType} onChange={(event) => setCountType(event.target.value as 'total' | 'average')}>
                                    <FormControlLabel value="total" control={<Radio />} label="Збирa" />
                                    <FormControlLabel value="average" control={<Radio />} label="Средње вредности" />
                                </RadioGroup>
                            </label>
                        </div>
                    </div>
                    <CategorySelector text={'Одаберите категорију'} categories={categories} onSelectCategory={selectCategory} selectedCategories={selectedCategories} />
                    <CategorySelector
                        text={'Одаберите подкатегорију'}
                        categories={subCategories}
                        onSelectCategory={setSelectedSubcategories}
                        selectedCategories={selectedSubcategories}
                        placeholder="Одаберите подкатегорију"
                    />
                </div>
            </div>
        </div>
    )
}

export default Filter
