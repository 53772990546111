import React, { useState } from 'react'
import { StepInterface } from './Step1'
import FileDescriptionTable from '../../../Components/FileDescriptionTable'
import { Button } from '@mui/material'
import { useFieldArray, useWatch } from 'react-hook-form'
import { Form7_8Entity } from '../../../../../models/Form7/Entities/Form7_8.entity'
import UploadFile from '../../../../../components/UploadFile'
import ActionButtons from '../../../../../components/ActionButtons'

export const Step4 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView, clearErrors }: StepInterface) => {
    const watchedValues = useWatch<Form7_8Entity>({ control })
    const [emptyArrayError, setEmptyArrayError] = useState(false)
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'o7_8_k4_dokumentaIzvestavanja',
    })
    const name = 'o7_8_k4_dokumentaIzvestavanja'

    const disabledNext = watchedValues[name]?.length === 0

    const header = ['Бр.', 'Назив документа	', 'Врста документа', 'Линк (ако постоји)']

    const handleAddArrayField = () => {
        setEmptyArrayError(false)
        append({ fileName: '', type: '', link: '' })
    }

    useState(() => {
        if (fields.length === 0) {
            append({ fileName: '', type: '', link: '' })
        }
    })

    const validateEmptyArray = () => {
        const isEmpty = !watchedValues[name]?.length
        setEmptyArrayError(isEmpty)
        return isEmpty
    }

    const goToNextStep = () => {
        const hasEmptyFields = (watchedValues?.o7_8_k4_dokumentaIzvestavanja ?? []).some(
            ({ fileName, type }) => !name || !type
        )
        if (!hasEmptyFields && !disabledNext && !validateEmptyArray()) {
            nextStep()
        } else if (adminView) {
            nextStep()
        }
    }

    return (
        <div>
            <div className="flex flex-col justify-between mb-[40px]">
                <h1 className="step-name mb-[10px]">Уколико имате додатне документе релевантне за извештавање молимо да их приложите:</h1>
                <p className="text-md mb-4">Пример докумената:</p>
                <ul className="list-disc list-inside space-y-2" style={{ textIndent: '-1.4em', paddingLeft: '1.4em' }}>
                    <li>Родна анализа исхода програма наставе и учења, извештаји Министарства просвете, и Завода за вредновање квалитета образовања и васпитања</li>
                    <li>Родна анализа наставних садржаја и уџбеника, извештаји Завода за унапређивање образовања и васпитања, Министарства просвете,и извештаји Завода за вредновање квалитета образовања и васпитања.</li>
                    <li>Извештаји Министарства просвете, о спровођењу програма образовања о сексуалном и репродуктивном здрављу у средњим школама, на годишњем нивоу.</li>
                    <li>Извештаји Министарства просвете, науке и технолошког развоја, МИЦС подаци.</li>
                </ul>
            </div>
            <FileDescriptionTable header={header} fields={fields} control={control} errors={errors} name={name} adminView={adminView} remove={remove} />

            <div className="flex flex-col mt-[56px]">
                <Button variant="contained" className="max-w-[172px] mt-[56px]" onClick={handleAddArrayField} disabled={adminView}>
                    Додај
                </Button>
                <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] ">{emptyArrayError ? 'Додајте барем једну активност!' : ''}</span>
            </div>

            <div className="my-10 gap-10">
                <h1>Молим Вас приложите планове за горе наведене програме ако нема јавно доступног линка</h1>
                <UploadFile control={control} name={'o7_8_k4_files'} disabled={adminView} form7 />
            </div>

            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={false} lastStep isAdmin={adminView} />
        </div>
    )
}
