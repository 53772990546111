import React, { useEffect, useState } from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { FixedSizeList, ListChildComponentProps } from 'react-window'
import { latinToCyrillic } from '../../utils/searchTranslation'

interface Item {
    name: string
    value: number
}

interface VirtualizedAutocompleteProps {
    items: Item[]
    label: string
    onChange?: any
    disabled?: boolean
    cityValue?: any
    borderColor?: string
}

export const VirtualizedAutocomplete: React.FC<VirtualizedAutocompleteProps> = ({ items, label, onChange, disabled = false, cityValue, borderColor = '#0000003B' }) => {
    const [value, setValue] = useState<Item | null>(null)

    useEffect(() => {
        if (cityValue) {
            setValue({
                name: cityValue.name,
                value: cityValue.zip,
            })
        }
    }, [cityValue])

    const renderRow = (props: ListChildComponentProps) => {
        const { index, style, data } = props
        const item = data[index]

        return (
            <li {...props} style={{ ...style, display: 'flex', alignItems: 'center', width: '100%' }}>
                {item}
            </li>
        )
    }

    const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(function ListboxComponent(props, ref) {
        const { children, ...other } = props
        const options = React.Children.toArray(children) as React.ReactNode[]

        return (
            <div ref={ref} {...other} style={{ paddingLeft: '10px', paddingRight: '5px', width: '100%' }}>
                <FixedSizeList height={250} width="100%" itemSize={46} itemCount={options?.length} itemData={options} overscanCount={5}>
                    {renderRow}
                </FixedSizeList>
            </div>
        )
    })

    const normalizeText = (text: string): string[] => {
        const latinVersion = text.toLowerCase()
        const cyrillicVersion = latinToCyrillic(text).toLowerCase()
        return [latinVersion, cyrillicVersion]
    }

    const filterOptions = (options: Item[], { inputValue }: { inputValue: string }) => {
        if (!inputValue) return options

        const [normalizedInputLatin, normalizedInputCyrillic] = normalizeText(inputValue)

        return options.filter((option) => {
            const [normalizedOptionLatin, normalizedOptionCyrillic] = normalizeText(option.name)

            return (
                normalizedOptionLatin.includes(normalizedInputLatin) ||
                normalizedOptionLatin.includes(normalizedInputCyrillic) ||
                normalizedOptionCyrillic.includes(normalizedInputLatin) ||
                normalizedOptionCyrillic.includes(normalizedInputCyrillic)
            )
        })
    }
    return (
        <Autocomplete
            value={value}
            onChange={(_, newValue) => {
                setValue(newValue)
                if (onChange) {
                    onChange(newValue)
                }
            }}
            noOptionsText={'Није пронађена ни једна ставка'}
            disableListWrap
            ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
            options={items}
            disabled={disabled}
            filterOptions={filterOptions}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={label}
                    InputLabelProps={{
                        style: { color: '#9e9e9e' },
                    }}
                />
            )}
            renderOption={(props, option) => (
                <li {...props} style={{ padding: '8px', width: '100%' }}>
                    {option.name}
                </li>
            )}
            className="!w-full"
            sx={{
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: borderColor,
                    },
                    '&:hover fieldset': {
                        borderColor: borderColor,
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: borderColor,
                    },
                },
            }}
        />
    )
}
