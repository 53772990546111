import React from 'react'
import { Control, useWatch } from 'react-hook-form'
import TextInputCell from '../../../components/Common/TextInputCell'
import { ReactComponent as Delete } from '../../../assets/svg/Delete.svg'
import { getEnumValues } from '../../../utils/mathUtils'
import SelectBody from '../../Form5/Components/SelectBody'
import { ImpactEnum, ProbabilityEnum, RiskLevelEnum, TblOblast6 } from '../../../models/Form6/Form6.entity'
import { Button } from '@mui/material'
import HelpButton from '../../../components/Common/HelpButton'

interface RiskTableInterface {
    control: Control<TblOblast6> | undefined
    fields: any[]
    remove: any
    header: string[]
    errors?: any
    disabled?: boolean
    showHelpMessage?: boolean
    helpName?: string
}

const matrix = [
    [0, 0, 1, 1, 1],
    [0, 1, 2, 2, 2],
    [0, 1, 2, 3, 3],
    [0, 1, 2, 3, 3],
    [0, 1, 2, 3, 3],
]

const probabilityValues = getEnumValues(ProbabilityEnum)
const impactValues = getEnumValues(ImpactEnum)
const riskLevelValues = getEnumValues(RiskLevelEnum)

function RiskTable({ control, fields, remove, header, errors, disabled, showHelpMessage = false, helpName = '' }: RiskTableInterface) {
    const watchedValues = useWatch<TblOblast6>({ control })

    const calculateRisk = (verovatnoca: string | undefined | null, uticaj: string | undefined | null) => {
        const verovatnocaValue = probabilityValues.indexOf(verovatnoca as string)
        const uticajValue = impactValues.indexOf(uticaj as string)
        if (verovatnocaValue === -1 || uticajValue === -1) return ''
        return riskLevelValues[matrix[verovatnocaValue][uticajValue]]
    }

    const getColor = (value: string) => {
        switch (value) {
            case 'Низак':
                return '#00AF50'
            case 'Умерени':
                return '#FFFF01'
            case 'Висок':
                return '#FFC000'
            case 'Веома висок':
                return '#FE0000'
            default:
                return '#FFFFFF'
        }
    }

    return (
        <table className="simple-table mt-[50px] gap-[6px]">
            <tr className="simple-table-header">
                <th className=" w-[114px] h-[54px]">Редни број</th>
                {header.map((head, index) => (
                    <th className=" w-[186px] h-[54px]" key={index}>
                        {head}
                    </th>
                ))}
                {showHelpMessage && <HelpButton name={helpName} />}
                <td className=" pl-3 flex flex-row gap-6 items-center invisible" style={{ border: 0 }}>
                    {/* <div className="w-fit cursor-pointer">
                        <Edit />
                    </div> */}
                    {fields.length > 1 && (
                        <Button className="w-fit cursor-pointer" disabled={disabled}>
                            <Delete />
                        </Button>
                    )}
                </td>
            </tr>
            {fields.map((field, index) => (
                <tr className="simple-table-row" key={field.id}>
                    <td className=" disabled w-[114px] h-[54px]">{index + 1}</td>
                    <td className=" editable w-[186px] h-[54px]" style={{ padding: 0, border: 0 }}>
                        <TextInputCell
                            name={`o6_k3_rizici.${index}.text`}
                            control={control}
                            placeholder="Tекст (50 карактера)"
                            errors={errors}
                            isRequired={!disabled}
                            maxNumberOfCharacter={50}
                            disabled={disabled}
                        />
                    </td>
                    <td className=" editable w-[186px] h-[54px]" style={{ padding: 0, border: 0 }}>
                        <SelectBody
                            control={control}
                            name={`o6_k3_rizici.${index}.verovatnoca`}
                            value={!!watchedValues.o6_k3_rizici ? watchedValues.o6_k3_rizici[index]?.verovatnoca : ''}
                            items={probabilityValues}
                            errors={errors}
                            isRequired={!disabled}
                            disabled={disabled}
                        />
                    </td>
                    <td className=" editable w-[186px] h-[54px]" style={{ padding: 0, border: 0 }}>
                        <SelectBody
                            control={control}
                            name={`o6_k3_rizici.${index}.uticaj`}
                            value={!!watchedValues.o6_k3_rizici ? watchedValues.o6_k3_rizici[index]?.uticaj : ''}
                            items={impactValues}
                            errors={errors}
                            isRequired={!disabled}
                            disabled={disabled}
                        />
                    </td>
                    <td className=" editable w-[186px] h-[54px]" style={{ padding: 0, border: 0 }}>
                        <TextInputCell
                            name={`o6_k3_rizici.${index}.tezina`}
                            control={control}
                            placeholder="Tекст (50 карактера)"
                            errors={errors}
                            isRequired={!disabled}
                            disabled={disabled}
                            maxNumberOfCharacter={50}
                        />
                    </td>
                    <td className=" editable w-[186px] h-[54px]" style={{ padding: 0 }}>
                        <div
                            className=" h-full content-center "
                            style={{
                                backgroundColor: getColor(
                                    calculateRisk(
                                        !!watchedValues.o6_k3_rizici ? watchedValues.o6_k3_rizici[index]?.verovatnoca : '',
                                        !!watchedValues.o6_k3_rizici ? watchedValues.o6_k3_rizici[index]?.uticaj : ''
                                    )
                                ),
                            }}
                        >
                            {calculateRisk(
                                !!watchedValues.o6_k3_rizici ? watchedValues.o6_k3_rizici[index]?.verovatnoca : '',
                                !!watchedValues.o6_k3_rizici ? watchedValues.o6_k3_rizici[index]?.uticaj : ''
                            )}
                        </div>
                    </td>
                    <td className=" pl-3 flex flex-row gap-6 items-center " style={{ border: 0 }}>
                        {/* <div className="w-fit cursor-pointer">
                            <Edit />
                        </div> */}
                        {fields.length > 1 && !disabled && (
                            <Button className="w-fit cursor-pointer" onClick={() => remove(index)}>
                                <Delete />
                            </Button>
                        )}
                    </td>
                </tr>
            ))}
        </table>
    )
}

export default RiskTable
