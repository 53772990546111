import React, { useState } from 'react'
import { Control, useFieldArray, useWatch } from 'react-hook-form'
import { Form7_14_1Entity } from '../../../../../models/Form7/Entities/Form7_14.entity'
import FileDescriptionTable from '../../../Components/FileDescriptionTable'
import { Button } from '@mui/material'
import UploadFile from '../../../../../components/UploadFile'
import ActionButtons from '../../../../../components/ActionButtons'
import FileInputComponent from '../../../Components/FileInputComponent'

export interface StepInterface {
    control?: Control<Form7_14_1Entity>
    nextStep: () => void
    previousStep: () => void
    previousButtonDisabled?: boolean
    errors?: any
    adminView?: boolean
    setValue?: any
    clearErrors?: any
}
export const Step1 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView, clearErrors }: StepInterface) => {
    const watchedValues = useWatch<Form7_14_1Entity>({ control })
    const [emptyArrayError, setEmptyArrayError] = useState(false)
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'o7_14_1_k1_fileDescription',
    })
    const name = 'o7_14_1_k1_fileDescription'

    let disabledNext = watchedValues[name]?.length === 0

    fields.forEach((item, index) => {
        const { fileName, type, link } = watchedValues[name]?.[index] || {}

        if (fileName === undefined || fileName === null || fileName === '') {
            disabledNext = true
        } else if (type === undefined || type === null || type === '') {
            disabledNext = true
        } else if (link === undefined || link === null || link === '') {
            disabledNext = true
        } else disabledNext = false
    })

    const header = ['Бр.', 'Назив документа', 'Врста документа', 'Линк ако је доступан']

    const handleAddArrayField = () => {
        setEmptyArrayError(false)
        append({ fileName: '', type: '', link: '' })
    }

    const validateEmptyArray = () => {
        if (watchedValues[name]?.length === 0 || watchedValues[name] === undefined) {
            setEmptyArrayError(true)
            return true
        }
        return false
    }

    const goToNextStep = () => {
        if (!disabledNext && !validateEmptyArray()) nextStep()
        else if (adminView) nextStep()
    }
    return (
        <div>
            <div className="flex flex-col justify-between">
                <h1 className="step-name mb-[10px]">Програми подршке друштвеним и технолошким иновацијама који посебно циљају жене</h1>

                <div className="flex flex-col gap-2">
                    <p>Пример документа:</p>
                    <p className="italic">• Донети родно одговорни докуменати јавних политика (нпр. планова) у години извештавања </p>
                    <p className="italic">• Спроведене родно осетљиве анализе потреба, ех-пост и ех-анте</p>
                    <p className="italic">• Извештаји о раду органа јавне власти у ЈИС о ефектима и уродњености јавних политика - РСЈП</p>
                </div>
            </div>
            <FileDescriptionTable header={header} fields={fields} control={control} errors={errors} name={name} adminView={adminView} remove={remove} />
            <div className="flex flex-col mt-[56px]">
                <Button variant="contained" className="max-w-[172px] mt-[56px]" onClick={handleAddArrayField} disabled={adminView}>
                    Додај датотеку
                </Button>
                <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] ">{emptyArrayError ? 'Додајте барем једну активност!' : ''}</span>
            </div>
            <div className="my-10 gap-10">
                <FileInputComponent
                    control={control}
                    name={'o7_6_k5_files'}
                    disabled={adminView}
                    errors={errors}
                    label="Молим Вас приложите планове за горе наведене програме ако нема јавно доступног линка"
                />
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}
