import React from 'react'
import { StepInterface } from './Step1'
import UploadFile from '../../../../../components/UploadFile'
import ActionButtons from '../../../../../components/ActionButtons'
import { isNotEmpty } from '../../../../../utils/Validation'
import { Form7_13Entity } from '../../../../../models/Form7/Entities/Form7_13.entity'
import { useWatch } from 'react-hook-form'
import FileInputComponent from '../../../Components/FileInputComponent'

export const Step3 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView, clearErrors }: StepInterface) => {
    const watchedValues = useWatch<Form7_13Entity>({ control })

    const isFileUploaded = watchedValues?.o7_13_k3_fajl && watchedValues.o7_13_k3_fajl?.id
    const goToNextStep = () => {
        if (isFileUploaded) nextStep()
        else if (adminView) {
            nextStep()
        }
    }

    return (
        <div>
            <FileInputComponent control={control} name={'o7_13_k3_fajl'} isRequired={true} label="Молимо Вас приложите извештај CEDAW" errors={errors} clearErrors={clearErrors} />
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}
