import React from 'react'
import { Control, useWatch } from 'react-hook-form'
import ActionButtons from '../../../../../components/ActionButtons'
import { Form7_10Entity } from '../../../../../models/Form7/Entities/Form7_10.entity'
import PositiveNumberInputCell from '../../../../Form2/Components/PositiveNumberInputCell'
import UploadFile from '../../../../../components/UploadFile'

export interface StepInterface {
    control?: Control<Form7_10Entity>
    nextStep: (skip?: boolean) => void
    previousStep: () => void
    previousButtonDisabled?: boolean
    errors?: any
    adminView?: boolean
    setValue?: any
}
const fileTypes = ['PDF']

function Step3({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView }: StepInterface) {
    const watchedValues = useWatch<Form7_10Entity>({ control })
    const fields: ['o7_10_k3_pristup', 'o7_10_k3_standard'] = ['o7_10_k3_pristup', 'o7_10_k3_standard']

    const disabledNext = !fields.every((e) => watchedValues[e] !== null && watchedValues[e] !== undefined && watchedValues[e]?.toString() !== '')

    const goToNextStep = () => {
        if (!disabledNext) {
            nextStep()
        } else if (adminView) {
            nextStep()
        }
    }
    return (
        <div>
            <h1 className="step-name">
                3. Број аката којима се обезбеђује приступ правди и правичном суђењу, и унапређује ефикасност институција у заштити права у складу са међународним стандардима и
                прописима:
            </h1>

            <div className=" w-[140px] h-[50px] flex items-center justify-center mt-[40px]">
                <PositiveNumberInputCell name={'o7_10_k3_pristup'} control={control} defaultValue="" errors={errors} isRequired={!adminView} height={52} disabled={adminView} />
            </div>
            <div className="h-[2px] mt-1"> {errors?.o7_10_k3_pristup && <p className="text leading-5 text-[#d32f2f] text-[0.75rem]">Попуните обавезно поље.</p>}</div>
            <h1 className="step-name mt-6">
                Број аката којима се обезбеђују стандарди и право на приступ правди и правичном суђењу, приступачност судова и тужилаштава, посебно особама из вишеструко
                дискриминисаних група како би се осигурала делотворна и ефикасна заштита права
            </h1>

            <div className=" w-[140px] h-[50px] flex items-center justify-center mt-[40px]">
                <PositiveNumberInputCell name={'o7_10_k3_standard'} control={control} defaultValue="" errors={errors} isRequired={!adminView} height={52} disabled={adminView} />
            </div>

            <div className="h-[2px] mt-1"> {errors?.o7_10_k3_standard && <p className="text leading-5 text-[#d32f2f] text-[0.75rem]">Попуните обавезно поље.</p>}</div>
            <h2 className="mt-[50px]">Молимо Вас да приложите све наведене акте ове врсте које до сада нисте прилижили и/или који су донешени у години извештавања</h2>
            <UploadFile control={control} name={'o7_10_k3_files'} disabled={adminView} fileTypes={fileTypes} errors={errors} isRequired={true} />
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step3
