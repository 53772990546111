import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useUser } from '../../auth/UseUser'
import HelpButton from '../../components/Common/HelpButton'
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { getFinishedForms, getRemainingForm, getStartedForms } from '../../server/server'
import { STATUS } from '../../models/CommonTypes'

const headerMapping = [
    { header: 'Година извештаја', value: 'year' },
    // { header: 'Категорија подносиоца', value: 'category' },
    // { header: 'Подкатегорија подносиоца', value: 'subCategory' },
    { header: 'Назив подносиоца', value: 'name', width: 300 },
    { header: 'Статус извештаја', value: 'status' },
    { header: 'Образац 1', value: 'f1' },
    { header: 'Образац 2', value: 'f2' },
    { header: 'Образац 3', value: 'f3' },
    { header: 'Образац 4', value: 'f4' },
    { header: 'Образац 5', value: 'f5' },
    { header: 'Образац 6', value: 'f6' },
    { header: 'Образац 7', value: 'f7' },
]

function HomeScreen() {
    const { user } = useUser()
    const navigate = useNavigate()
    const [remainingForms, setRemainingForms] = useState<any[]>([])
    const [startedForms, setStartedForm] = useState<any[]>([])
    const [finishedForms, setFinishedForms] = useState<any[]>([])

    useEffect(() => {
        loadRemainingForms()
        loadStartedForms()
        loadFinishedForms()
    }, [])

    const loadRemainingForms = async () => {
        const resp = await getRemainingForm()
        if (resp.status === 200) {
            setRemainingForms(resp.data)
        }
    }
    const loadStartedForms = async () => {
        const resp = await getStartedForms()
        if (resp.status === 200) {
            setStartedForm(resp.data)
        }
    }
    const loadFinishedForms = async () => {
        const resp = await getFinishedForms()
        if (resp.status === 200) {
            console.log(resp.data)
            setFinishedForms(resp.data.reports)
        }
    }

    return (
        <div className="!w-full px-[100px] bg-[#ffffff] pt-[52px] relative">
            <div className="absolute top-5 right-10 ">
                <HelpButton name="home_screen_help" />
            </div>

            <h2 className="text-[24px] font-light max-w-[800px]">Подношење образаца</h2>

            <div className="pt-[10px]">
                <p>Потребно је да попуните следеће обрасце:</p>
                <div className="pb-[40px] pt-[20px] gap-[10px] flex flex-row">
                    {remainingForms.map((button, index) => (
                        <ObrazacButton key={index} path={button.path} text={button.name} />
                    ))}
                </div>
            </div>
            {!!startedForms.length && (
                <>
                    <div className="flex flex-row items-center gap-4">
                        <h2 className="text-[24px] font-light max-w-[800px]">Започети обрасци</h2>
                    </div>
                    <div className="pt-[10px]">
                        <p>Потребно је да завршите унос следећих образаца:</p>

                        <div className="pb-[40px] pt-[20px] gap-[10px] flex flex-row">
                            {startedForms.map((button, index) => (
                                <ObrazacButton key={index} path={button.path} text={button.name} />
                            ))}
                        </div>
                    </div>
                </>
            )}
            <div className="flex flex-row items-center gap-4">
                <h2 className="text-[24px] font-light max-w-[800px]">Поднети обрасци</h2>
            </div>
            <div className="!w-full py-10 min-h-full">
                <Paper>
                    <TableContainer>
                        <Table className="overflow-x-auto w-full">
                            <TableRow>
                                {headerMapping.map((item, index) => (
                                    <TableCell className="min-w-[150px]" key={index} style={{ minWidth: item.width ?? 150 }}>
                                        <div
                                            className={`!flex !flex-row justify-center items-center px-2 text-center ${
                                                item.header.includes('бра') ? 'justify-center' : 'justify-between'
                                            } `}
                                        >
                                            {item.header}
                                        </div>
                                    </TableCell>
                                ))}
                            </TableRow>
                            <TableBody>
                                {finishedForms.map((r) => (
                                    <TableRow key={r.id}>
                                        <TableCell height={52}>
                                            <p className="px-2 text-center">{r.report_year}</p>
                                        </TableCell>
                                        <TableCell height={52} width={400}>
                                            <p className="px-2">{r.submittedBy.subjectName}</p>
                                        </TableCell>
                                        <TableCell height={52} align="center">
                                            {STATUS[r.status as keyof typeof STATUS]}
                                        </TableCell>
                                        {r?.obrasci?.map((items: any, index: number) => {
                                            const item = !!items?.length ? items[0] : {}
                                            return (
                                                <TableCell align="center" className={`!min-w-[150px] !max-w-[150px]`}>
                                                    {!!item.report_id ? (
                                                        <Button
                                                            style={{ width: '74px', backgroundColor: '#0D47A1' }}
                                                            key={item.id}
                                                            onClick={() => {
                                                                const addonString = item.orgType === 'stranka' || item.orgType === 'sindikat' ? `/${item.type}` : ''

                                                                navigate(`/obrazac${index + 1}${addonString}?formId=${item.report_id}`)
                                                            }}
                                                            variant="contained"
                                                        >
                                                            Отвори
                                                        </Button>
                                                    ) : (
                                                        '-'
                                                    )}
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </div>
            <div className="flex flex-col">
                <p>TEMP FORM 7</p>
                <div className="flex flex-row gap-5">
                    <ObrazacButton path="/obrazac7/1" text={'form 7.1'} />
                    <ObrazacButton path="/obrazac7/2" text={'form 7.2'} />
                    <ObrazacButton path="/obrazac7/3" text={'form 7.3'} />
                    <ObrazacButton path="/obrazac7/4-1" text="form 7.4.1" />
                    <ObrazacButton path="/obrazac7/4-2" text="form 7.4.2" />
                    <ObrazacButton path="/obrazac7/5" text="form 7.5" />
                    <ObrazacButton path="/obrazac7/6" text="form 7.6" />
                    <ObrazacButton path="/obrazac7/7" text="form 7.7" />
                    <ObrazacButton path="/obrazac7/8" text="form 7.8" />
                    <ObrazacButton path="/obrazac7/9" text="form 7.9" />
                    <ObrazacButton path="/obrazac7/10" text={'form 7.10'} />
                    <ObrazacButton path="/obrazac7/11" text={'form 7.11'} />
                    {/* <ObrazacButton path="/obrazac7/12" text={'form 7.12'} /> */}

                    <ObrazacButton path="/obrazac7/13" text="form 7.13" />
                    <ObrazacButton path="/obrazac7/14-1" text="form 7.14.1" />
                    <ObrazacButton path="/obrazac7/14-2" text="form 7.14.2" />
                    <ObrazacButton path="/obrazac7/15" text="form 7.15" />
                </div>
            </div>
        </div>
    )
}

export const ObrazacButton = ({ path, text, color = '#32a869', disabled = false }: { path: string; text: string; color?: string; disabled?: boolean }) => {
    const navigate = useNavigate()
    return (
        <Button variant="contained" style={{ backgroundColor: disabled ? '#e0e0e0' : '#0D47A1' }} disabled={disabled} onClick={() => navigate(path)}>
            {text}
        </Button>
    )
}
export default HomeScreen
