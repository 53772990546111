import React, { useState } from 'react'
import { Control, useFieldArray, useWatch } from 'react-hook-form'
import ActionButtons from '../../../../../components/ActionButtons'
import UploadFile from '../../../../../components/UploadFile'
import { Button } from '@mui/material'
import FileDescriptionTable from '../../../Components/FileDescriptionTable'
import { Form7_15Entity } from '../../../../../models/Form7/Entities/Form7_15.entity'

export interface StepInterface {
    control?: Control<Form7_15Entity>
    nextStep: () => void
    previousStep: () => void
    previousButtonDisabled?: boolean
    errors?: any
    adminView?: boolean
    setValue?: any
    clearErrors?: any
}

export const Step9 = ({ control, nextStep, previousStep, previousButtonDisabled = false, errors, adminView, clearErrors }: StepInterface) => {
    const watchedValues = useWatch<Form7_15Entity>({ control })
    const [emptyArrayError, setEmptyArrayError] = useState(false)
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'o7_15_k9_fileDescription',
    })
    const name = 'o7_15_k9_fileDescription'

    let disabledNext = watchedValues[name]?.length === 0

    fields.forEach((item, index) => {
        const { fileName, type, link } = watchedValues[name]?.[index] || {}

        if (fileName === undefined || fileName === null || fileName === '') {
            disabledNext = true
        } else if (type === undefined || type === null || type === '') {
            disabledNext = true
        } else if (link === undefined || link === null || link === '') {
            disabledNext = true
        } else disabledNext = false
    })

    const header = ['Бр.', 'Назив документа', 'Врста', 'Линк ако постоји']

    const handleAddArrayField = () => {
        setEmptyArrayError(false)
        append({ fileName: '', type: '', link: '' })
    }

    const validateEmptyArray = () => {
        if (watchedValues[name]?.length === 0 || watchedValues[name] === undefined) {
            setEmptyArrayError(true)
            return true
        }
        return false
    }

    const goToNextStep = () => {
        if (!disabledNext && !validateEmptyArray()) nextStep()
        else if (adminView) nextStep()
    }
    return (
        <div>
            <div className="flex flex-col justify-between">
                <h1 className="step-name mb-[10px]">Уколико имате додатне документе релевантне за извештавање молимо да их приложите:</h1>
                <div className="flex flex-col gap-2">
                    <p>Пример документа:</p>
                    <p className="italic">
                        • Извештаји Министарства здравља о доступности и приступачности здравствених услуга; SILC, Национални извештај о социјалном укључивању и смањењу сиромаштва
                        у Републици Србији, ЕHIS,
                    </p>
                    <p className="italic">• Извештаји Министарства здравља, Института за јавно здравље о коришћењу здравствених услуга и здравственог стања становништва.</p>
                    <p className="italic">
                        • Извештаји Министарства здравља, Институти за јавно здравље (Канцеларије за скрининг рака) о организовању и одзиву на организоване превентивне прегледе
                    </p>
                    <p className="italic">• Извештаји о унапређењу квалитета здравствене заштите</p>
                </div>
            </div>
            <FileDescriptionTable header={header} fields={fields} control={control} errors={errors} name={name} adminView={adminView} remove={remove} />

            <div className="flex flex-col mt-[56px]">
                <Button variant="contained" className="max-w-[172px] mt-[56px]" onClick={handleAddArrayField} disabled={adminView}>
                    Додај датотеку
                </Button>
                <span className="text-[#d32f2f] text-[12px] leading-5 h-5 ml-[13px] ">{emptyArrayError ? 'Додајте барем једну активност!' : ''}</span>
            </div>

            <div className="mt-10 gap-10">
                <UploadFile control={control} name={'o7_15_k9_file'} disabled={adminView} />
            </div>
            <ActionButtons nextButton={goToNextStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}
