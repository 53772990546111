import React, { useEffect, useState } from 'react'
import { FileUploader } from 'react-drag-drop-files'
import UploadFileMock from './Common/UploadFileMock'
import { File as FileInterface } from '../models/File.entity'
import { Control, Controller, useFieldArray, useWatch } from 'react-hook-form'
import { MoonLoader } from 'react-spinners'
import { ReactComponent as Trash } from '../assets/svg/Trash.svg'
import { deleteFile, getFilesForForm, uploadFile, viewFile } from '../server/server'
import { TblOblast3 } from '../models/Form3/Form3.entity'
import { Button } from '@mui/material'
import { FileViewModal } from './Common/FileViewModal'

interface UploadingFile {
    id?: string
    status: 'uploading' | 'ok' | 'error'
    name: string
}

export interface UploadFileProps {
    control?: Control<any>
    name: string
    fileTypes?: string[]
    maxSize?: number // Size in Megabytes
    disabled?: boolean
    errors?: any
    isRequired?: any
    error?: any
    clearErrors?: any
    obrazlozenjeName?: any
    form7?: boolean
}
//TODO Dodati otvaranje fajlova

function UploadFile({
    control,
    name,
    fileTypes = ['PDF'],
    maxSize = 2,
    disabled = false,
    errors,
    isRequired,
    error,
    clearErrors,
    obrazlozenjeName,
    form7 = false,
}: UploadFileProps) {
    const [newEntity, setNewEntity] = useState<FileInterface>({})
    const [fileUrl, setFileUrl] = useState('')
    const { append, remove } = useFieldArray({
        control,
        name: name,
    })
    const [modalOpen, setModalOpen] = useState(false)
    const [files, setFiles] = useState<UploadingFile[]>([])
    const watchedValues = useWatch({ control })
    useEffect(() => {
        if (watchedValues[name]?.length === 0 && !name.includes('submitted_by')) {
            getFormFiles()
        } else if (name.includes('submitted_by')) {
            watchedValues?.submitted_by?.files?.forEach((file: any) => {
                setFiles((prevFiles) => [...prevFiles, { id: file.id, name: file.name, status: 'ok' }])
            })
        } else {
            watchedValues[name]?.forEach((file: any) => {
                setFiles((prevFiles) => [...prevFiles, { id: file.id, name: file.name, status: 'ok' }])
            })
        }
    }, [watchedValues?.report_id])

    const getFormFiles = async () => {
        const resp = await getFilesForForm(watchedValues.report_id, name)
        if (resp.status === 200) {
            if (Array.isArray(resp?.data)) {
                resp?.data?.forEach((file) => {
                    setFiles((prevFiles) => [...prevFiles, { id: file.id, name: file.name, status: 'ok' }])
                    append({ id: file.id, name: file.name, path: file.path })
                })
            } else {
                console.error('Expected an array of files but received:', resp.data)
            }
        }
    }

    const handleFileChange = async (file: any) => {
        if (file) {
            const result = await uploadFile(file)

            if (result) {
                setNewEntity({})
                setFiles([...files, { id: result.id, name: file.name, status: 'ok' }])
                append({ id: result.id, name: file.name, path: file.path })
            } else {
                setFiles([...files, { name: file.name, status: 'error' }])
            }
        }
    }

    const removeFile = async (index: number) => {
        const fileToRemove = files[index]
        const resp = await deleteFile(fileToRemove.id, fileToRemove.name)
        if (resp.status === 201) {
            setFiles(files.filter((e: any, i: number) => i !== index))
            remove(index)
        } else {
            alert(`Грешка при брисању датотеке: ${resp.status}`)
        }
    }
    return (
        <>
            <Controller
                control={control}
                name={name}
                rules={{ required: isRequired }}
                render={({ field }) => (
                    <>
                        <div className={`mt-[20px] mb-[10px] ml-[5px] w-[445px] ${disabled ? 'cursor-auto' : 'cursor-pointer'}`}>
                            <FileUploader
                                handleChange={(file: any) => {
                                    handleFileChange(file)
                                    if (clearErrors) clearErrors(obrazlozenjeName)
                                }}
                                onTypeError={() => window.alert('Фајл који сте послали није доброг формата!')}
                                name="file"
                                types={fileTypes}
                                classes="bg-primary"
                                hoverTitle="​"
                                maxSize={maxSize}
                                disabled={disabled}
                                onSizeError={() => window.alert('Фајл који сте послали је превелик!')}
                                tabIndex={disabled ? -1 : 0}
                            >
                                <UploadFileMock fileTypes={['ПДФ']} disabled={disabled} error={errors && errors[name]} />
                            </FileUploader>
                        </div>
                        <div className="h-[10px]">{errors && errors[name] && <p className="text-textError">Обавезно додајте фајл</p>}</div>
                    </>
                )}
            />
            {!form7 ? (
                <p className="opacity-[87%] font-thin text-[14px] mt-[20px]">
                    Напомена: морате уплоадовати ПДФ до 2МБ. Одлука мора да буде скениран оригинал са печатом и потписом или електронски потписан ПДФ.
                </p>
            ) : (
                <p className="opacity-[87%] font-thin text-[14px] mt-[20px]">Напомена: Дозвољени формат ПДФ до 2МБ.</p>
            )}
            {!!files.length && (
                <div className="mt-[20px] mb-[40px]">
                    <p className="mb-[10px]">Послати фајлови:</p>
                    <div className="flex flex-col gap-[10px]">
                        {files.map((e, index) => (
                            <div key={index} className="flex flex-row">
                                <div className="h-[46px] rounded-[4px] border w-[445px] flex flex-row items-center px-[10px] border-[#C5FD8D] mr-[15px]">
                                    <p className="flex-1 text-[16px]">{e.name}</p>
                                    {e.status === 'uploading' ? (
                                        <MoonLoader size={20} color={'#123abc'} loading={true} className="ml-2" />
                                    ) : e.status === 'error' ? (
                                        <i>Дошло је до грешке</i>
                                    ) : null}
                                </div>
                                {e.status === 'error' && (
                                    <button onClick={() => removeFile(index)}>
                                        <Trash />
                                    </button>
                                )}
                                {e.status === 'ok' && !disabled ? (
                                    <>
                                        <Button variant="contained" className="!bg-textError !mr-[10px]" onClick={() => removeFile(index)}>
                                            Обриши
                                        </Button>
                                        <Button
                                            variant="contained"
                                            onClick={async () => {
                                                const resp = await viewFile(e.id)
                                                if (resp.data.url) {
                                                    setFileUrl(resp.data.url)
                                                    setModalOpen(true)
                                                } else {
                                                    console.error('Failed to fetch the file URL:', resp)
                                                }
                                            }}
                                        >
                                            Отвори
                                        </Button>
                                    </>
                                ) : (
                                    <Button
                                        variant="contained"
                                        onClick={async () => {
                                            const resp = await viewFile(e.id)

                                            if (resp.data.url) {
                                                setFileUrl(resp.data.url)
                                                setModalOpen(true)
                                            } else {
                                                console.error('Failed to fetch the file URL:', resp)
                                            }
                                        }}
                                    >
                                        Отвори
                                    </Button>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            )}
            <FileViewModal isOpen={modalOpen} onClose={() => setModalOpen(false)} link={fileUrl} />
        </>
    )
}

export default UploadFile
