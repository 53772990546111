import { useEffect, useState } from 'react'
import { useFieldArray, useWatch } from 'react-hook-form'
import { Button } from '@mui/material'
import ActionButtons from '../../../../../components/ActionButtons'
import { Form7_11Entity } from '../../../../../models/Form7/Entities/Form7_11.entity'
import { StepInterface } from './Step1'
import Form7_11_k2Table from '../../../Components/Form7_11_k2Table'
import Form7_11_k4Table from '../../../Components/Form7_11_k4Table'

export interface RowInterface {
    label: string
    subtitle: string
    field: number
}
const arrayName1 = 'o7_11_k4_krivicnePrijave'
const arrayName2 = 'o7_11_k4_pravosnaznePresude'

const HEADER_LIST = ['Број кривичних пријава', 'Врста дела']

function Step4({ control, nextStep, previousStep, previousButtonDisabled = false, errors, setValue, adminView }: StepInterface) {
    const watchedValues = useWatch<Form7_11Entity>({ control })

    const {
        fields: fields1,
        append: append1,
        remove: remove1,
    } = useFieldArray({
        control,
        name: arrayName1,
    })

    const {
        fields: fields2,
        append: append2,
        remove: remove2,
    } = useFieldArray({
        control,
        name: arrayName2,
    })

    useEffect(() => {
        if (!fields1.length) append1([{ broj: undefined, vrsta: '' }])
        if (!fields2.length) append2([{ broj: undefined, vrsta: '' }])
    }, [])

    const handleAddField1 = () => {
        append1({ broj: undefined, vrsta: '' })
    }

    const handleAddField2 = () => {
        append2({ broj: undefined, vrsta: '' })
    }

    const validateFields1 = (): boolean => {
        if (adminView) {
            return true
        }
        const newMap = watchedValues.o7_11_k4_krivicnePrijave?.map((field) => !!field.broj && field.vrsta != '')
        return newMap?.includes(false) ? true : false
    }
    const validateFields2 = (): boolean => {
        if (adminView) {
            return true
        }
        const newMap = watchedValues.o7_11_k4_pravosnaznePresude?.map((field) => !!field.broj && field.vrsta != '')
        return newMap?.includes(false) ? true : false
    }

    const disabledNext = validateFields1() || validateFields2()
    const disabledAddMore1 = validateFields1()
    const disabledAddMore2 = validateFields2()

    const goToNexStep = () => {
        if (!disabledNext) {
            nextStep()
        } else if (adminView) {
            nextStep()
        }
    }
    return (
        <div>
            <h1 className="step-name mb-1">
                4. Проценат/број кривичних пријава/оптужења за кривичнa дела са елементима насиља, посебно за кривична дела сексуалног насиља и трговине људима у години
                извештавања:
            </h1>

            <Form7_11_k4Table name="o7_11_k4_krivicnePrijave" fields={fields1} remove={remove1} control={control} header={HEADER_LIST} errors={errors} disabled={adminView} />
            <p className="ml-5 min-h-[30px] text-textError text-[0.75rem] text leading-[30px]">{errors['o7_11_k4_krivicnePrijave'] ? 'Попуните обавезно поље.' : ''}</p>

            <Button variant="contained" onClick={handleAddField1} disabled={disabledAddMore1}>
                Додај још
            </Button>

            <Form7_11_k4Table name="o7_11_k4_pravosnaznePresude" fields={fields2} remove={remove2} control={control} header={HEADER_LIST} errors={errors} disabled={adminView} />
            <p className="ml-5 min-h-[30px] text-textError text-[0.75rem] text leading-[30px]">{errors['o7_11_k4_pravosnaznePresude'] ? 'Попуните обавезно поље.' : ''}</p>

            <Button variant="contained" onClick={handleAddField2} disabled={disabledAddMore2}>
                Додај још
            </Button>

            <ActionButtons nextButton={goToNexStep} previousButton={previousStep} nextButtonDisabled={false} previousButtonDisabled={previousButtonDisabled} />
        </div>
    )
}

export default Step4
